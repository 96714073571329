import React from 'react'
import Box from '../../../layout/Box'

import {ManagerComponent} from "../ManagerComponent";

import * as _ from 'lodash';
import Flex from "givapp-design/src/components/Flex";
import styled from "@emotion/styled";
import * as space from "givapp-design/src/tokens/space";
import * as typography from "givapp-design/src/tokens/typography";
import * as border from "givapp-design/src/tokens/border";
import LoadingCard from "../components/LoadingCard";
import Header from "../../../structure/Header";
import MainHeading from "../../../style/text/MainHeading";
import {fetchDonorNotesForOrganization} from "../ManagerApi";
import Text from "givapp-design/src/components/Text";
import {rem} from "givapp-design/src/lib/tools";
import Select from "givapp-design/src/components/Select";
import {dateRangeOptions, donationTypeOptions} from "../Donations";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Table from "givapp-design/src/components/Table";
import TableRow from "givapp-design/src/components/TableRow";
import {Button, TableHead} from "givapp-design";
import TableData from "givapp-design/src/components/TableData";
import moment from "moment";

const TableControls = styled(Flex)`
  background-color: #F3F5F5;
  border-top: 1px solid #DCE0E2;
`


const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const DateRangeElement = styled(Box)`
  /* Stripe Input Helper */

  .react-daterange-picker {
    width: 100%;
    height: ${space.s6};
    font-family: ${typography.fontFamily};
    background-color: ${({theme}) => theme.color.white};
    color: ${({theme}) => theme.color.black1};

    .react-daterange-picker__wrapper {
      border-width: ${border.borderWidth};
      border-style: ${border.borderStyleDefault};
      border-color: ${({theme}) => theme.color.gray3};
      border-radius: ${border.borderRadiusSm};

      .react-daterange-picker__inputGroup {
        text-align: center;
      }
    }


    .react-calendar {
      border-width: ${border.borderWidth};
      border-style: ${border.borderStyleDefault};
      border-color: ${({theme}) => theme.color.gray3};

      border-radius: ${border.borderRadiusSm};

      .react-calendar__tile--active {
        background-color: ${({theme}) => theme.color.classicBlue3};
      }

      .react-calendar__tile--now {
        background-color: ${({theme}) => theme.color.gray3};

      }
    }

    &.react-daterange-picker--open {
      .react-daterange-picker__wrapper {
        color: ${({theme}) => theme.color.darkGray5};
        border-color: ${({theme}) => theme.color.classicBlue3};
      }
    }
  }
`

export class Notes extends ManagerComponent {
  constructor() {

    super();
    this.state = {
      page: 0,
      size: 10,
      total: 0,
      donations: [],
      showDateRange: false,
      loading: true
    }

  }

  componentDidMount() {
    super.componentDidMount();
    this.props.onUserUpdated(() => {
      this.refresh();
    })
    console.log('Notes.componentDidMount', this.props.donor);
    // Set active page state
    //const {setPageId} = this.props
    //setPageId('donors');

    console.log('Notes.componentDidMount:loading', this.state.loading);
    this.setState(_.extend({
      loading: true,
      donorId: this.props.donor.id
    }, this.state), () => {
      this.refresh();
    });
  }

  refresh() {
    console.log('Notes:refresh');
    let params = {};
    if (this.state.dateRange && this.state.dateRange !== 'CUSTOM') {
      params = {
        ...(_.find(dateRangeOptions, (row) => {
          return row.value === this.state.dateRange
        }).dates()),
        ...params
      };
    } else if (this.state.dateRange === 'CUSTOM' && this.state.customDateRange) {
      params = {
        ...(this.state.customDateRange),
        ...params
      };
    }

    fetchDonorNotesForOrganization(this.state.organization.id, this.state.donorId, params).then((results) => {
      this.setState({
        total: "TBD",
        notes: results,
        loading: false
      });
    })
  }

  selectDateRange = (newValue) => {
    console.log('selectDateRange', newValue);

    this.setState({
      showDateRange: newValue ? newValue.value === 'CUSTOM' : false,
      dateRange: newValue ? newValue.value : null
    }, () => {
      this.refresh();
    });
  };

  selectSpecificDates = (newValue) => {
    console.log('selectSpecificDates', newValue);
    this.setState({
      showDateRange: true,
      dateRange: 'CUSTOM',
      customDateRange: newValue ? {
        fromDate: newValue[0],
        toDate: newValue[1]
      } : null
    }, () => {
      this.refresh();
    });
  }

  render() {
    const {
      loading,
      total,
      showDateRange,
      notes
    } = this.state;

    let customDateRange = this.state.customDateRange;
    if (!customDateRange) {
      customDateRange = [new Date(), new Date()]
    }
    return (

      <Box>
        <MainHeading>Notes</MainHeading>
        {!!loading == true &&
          <LoadingCard>Loading...</LoadingCard>
        }
        {!!loading == false && notes && notes.length == 0 &&
          <Box>
            <Text>No notes yet.</Text>
          </Box>
        }


        <TableControls mt={rem(4)} w={1.0} flexDirection={'row'} justifyContent={'space-between'}
                       alignContent={'space-between'}
                       p={rem(4)}>
          <Box w={[0.3]}>
            {/*  <Input small isSearch theme={selectedTheme} placeholder={'Quick filter'}
                   value={filter}
                   onChange={(e) => this.filterDonations(e)}
            ></Input>*/}
          </Box>
          <Box w={[0.5]}>
            <Flex flexDirection={'row'} justifyContent={'space-between'}>
              <Box w={[0.5]}>
                <Flex flexDirection={'column'} justifyContent={'space-around'}>
                  <Box w={[1]}>
                    <Select
                      clearable
                      small
                      options={dateRangeOptions}
                      placeholder="Date Range"
                      onChange={selected => this.selectDateRange(selected)}
                    />
                  </Box>
                  {!!showDateRange &&
                    <DateRangeElement w={[1]} mt={rem(5)}>
                      <DateRangePicker
                        onChange={selected => this.selectSpecificDates(selected)}
                        value={[customDateRange.fromDate, customDateRange.toDate]}
                      /></DateRangeElement>
                  }
                </Flex>
              </Box>
              <Box w={[0.5]}>
                <Flex flexDirection={'row'} justifyContent={'space-between'}>
                  {/*<Box w={[0.5]} ml={rem(5)}>
                    <Select
                      clearable
                      small
                      options={donationTypeOptions}
                      placeholder="Donation Type"
                      onChange={selected => this.selectDonationType(selected)}
                    />
                  </Box>*/}
                  {/*<Box w={[0.5]} ml={rem(5)}>
                    <Select
                      clearable
                      small
                      options={campaignOptions}
                      placeholder="Campaign"
                      onChange={selected => this.selectCampaign(selected)}
                    />
                  </Box>*/}
                </Flex>
              </Box>
            </Flex>
          </Box>
        </TableControls>
        {!!notes && notes.length > 0 &&
          <>
            <Table fixed>
              <thead>
              <TableRow>
                <TableHead>
                  Author
                </TableHead>
                <TableHead>
                  Note
                </TableHead>
                <TableHead>
                  Date
                </TableHead>
                <TableHead>
                  Remove
                </TableHead>
              </TableRow>
              </thead>
              <tbody>
              {notes.map(note => (

                <TableRow key={note.id}>
                  <TableData>{note?.get('author')?.get('fullName')}</TableData>
                  <TableData>{note.get('note')}</TableData>
                  <TableData>{moment(note.createdAt).format('MM-DD-YYYY')}</TableData>
                  <TableData>
                    <Button>Remove</Button>
                  </TableData>

                </TableRow>

              ))}

              </tbody>
            </Table>

            <Box><Text gray>{notes.length} total found</Text></Box>
            {/*<Box>
              <Text>
                <a onClick={(e) => this.prev(e)}>Prev</a>
                &nbsp;
                <a onClick={(e) => this.next(e)}>Next</a>
              </Text>
            </Box>*/}
          </>
        }

      </Box>

    )
  }
}

export default Notes
