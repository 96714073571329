import React from "react";
import {CardCvcElement, CardElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import * as numeral from "numeral";
//import Box from "../../layout/Box";
//import {rem} from "../../../lib/tools";
//import Text from "../../style/text/Text";
//import Flex from "../../layout/Flex";
//import UIMessage from "../../style/text/UIMessage";
//import Button from "../../style/elements/Button";
import {color} from "givapp-design";
import {Box, Button, Flex, Text, UIMessage} from "givapp-design";
import {rem} from "givapp-design/src/lib/tools";
import * as typography from "givapp-design/src/tokens/typography";

//const stripe = useStripe();
//const elements = useElements();

class CheckoutForm2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      recurringPaymentOn1st: false,
      recurringPaymentOn15th: false
    }


    this.submit = this.submit.bind(this);
    //this.handleAmountChange = this.handleAmountChange.bind(this);
  }

  handleChange = (key, e) => (
    e => {
      console.log(key, e.target.value, e.target.value === true);

      if (isNaN(e.target.value)) {
        this.setState({[key]: e.target.value})
      } else {
        this.setState({[key]: numeral(e.target.value).value()})
      }
    }
  )

  handleAmountChange = (value) => {
    console.log(value);
  }


  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  async submit(ev) {
    ev.preventDefault();
    console.log('CheckoutForm:submit');
    this.setState({submitted: true});

    let promise = this.props.onSubmit(this.state);

    const {stripe, elements} = this.props;

    promise.then(async (result) => {
      console.log('CheckoutForm:submit successful');

      const cardElement = elements.getElement(CardElement);

      const paymentMethodResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      });

      if (paymentMethodResult.error) {
        this.setState({
          submitted: false
        });
        this.props.onError(paymentMethodResult.error);
      } else {
        this.setState({
          submitted: true,
          error: null
        });

        this.props.onPaymentMethodCreation(paymentMethodResult.paymentMethod, this.props.amount, this.props.recurringPaymentOn1st, this.props.recurringPaymentOn15th, this.props.coverTransactionFees);
      }

    }, (error) => {
      console.log('CheckoutForm:submit error', error);
      this.setState({
        submitted: false
      });

      this.props.onError(error);
    });


  }

  isValid() {
    //const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const valid =
      this.props.amount && this.props.amount > 0;

    return !this.state.submitted && valid;
  }

  reject(e) {
    var k = e.keyCode;
    var keys = [8, 190, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
    if (keys.indexOf(k) < 0) {
      e.preventDefault();
    }
  }

  render() {

    const {error, theme} = this.props;
    console.log("_CheckoutForm2:render:theme", theme);

    console.log("_CheckoutForm2:render:error", error);
    /*
                onChange={this.handleChange('amount')}*/
    return (

      <form onSubmit={this.submit} disabled={!this.isValid() ? 'disabled' : false} method="post">


        <Box mt={rem(2)} mb={rem(2)}>
          <Text>Payment Card</Text>
        </Box>

        <Box mt={rem(2)}>
          <CardElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            options={{
              // fonts: {
              //   family: typography.fontFamily,
              //   src: 'url(https://admin.givapp.com/<PATH>/<TO>/GivAppDMSans-Regular.woff)',
              //   weight: typography.fontWeightRegular,
              //   style: 'normal'
              // },
              style: {
                base: {
                  fontFamily: typography.fontFamily,
                  // don't use rem because it's calculated with Stripe's iFrame element
                  fontSize: '18px', //typography.fontSizeTextLarge,
                  color: theme.color.black3,
                  '::placeholder': {
                    color: theme.color.gray5,
                  },
                },
                invalid: {
                  color: theme.color.negative4,
                  iconColor: theme.color.negative3
                },
              },
            }}
          />
        </Box>
        {!!error &&
        <Box mt={3}>
          <UIMessage type="error">Error: {error.message}</UIMessage>
        </Box>
        }

        <Flex mt={2} flexDirection={['row']} justifyContent="space-between" alignItems={'center'}>
          <Box mt={rem(2)} width={[1]}>
            <Button htmlButton type="submit" disabled={this.props.saving || this.props.valid}>Submit</Button>
          </Box>
        </Flex>
      </form>);
  }
}

export default CheckoutForm2

//const CheckoutForm2 = injectStripe(_CheckoutForm2);
/*STRIPE ELEMENTS*/

const handleBlur = () => {
  console.log('[blur]');
};
const handleChange = (change) => {
  console.log('[change]', change);
};
const handleFocus = () => {
  console.log('[focus]');
};
const handleReady = () => {
  console.log('[ready]');
};

/*
const createOptions = (fontSize, padding) => {
  console.log('CheckoutForm2:createOptions')
  const options = {
    style: {
      base: {
        fontSize,
        color: color.black,
        letterSpacing: '0.025em',
        fontFamily: type.family.default,
        '::placeholder': {
          'font-weight': type.weight.regular,
          'color': color.lightGray
        },
        border: '2px solid red',
        ...(padding ? {padding} : {}),
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
  console.log('CheckoutForm2:createOptions', options);
  return options;
};
*/
