import React from 'react'
import styled from 'styled-components'

import LinkList from '../../parts/LinkList'
import MainNavItem from './MainNavItem'

import { parseJSON } from '../../../lib/tools'

// Get navigation link list from JSON file
import navigationData from '../../../content/main-nav.json'
import adminNavigationData from '../../../content/admin-nav.json'

const navigation = parseJSON(navigationData)
const adminNavigation = parseJSON(adminNavigationData)

const Nav = styled.nav`
  display: block;
`

export class Navigation extends React.Component {
  // constructor() {
  //   super()
  //
  //   this.state = {
  //     isAdmin: false
  //   }
  // }

  render() {
    const { user, pageId } = this.props
    const isAdmin = typeof user.get('isAdmin') !== 'undefined' && user.get('isAdmin')

    return (
      <Nav itemScope="itemscope" itemType="https://schema.org/SiteNavigationElement">
        {!!isAdmin ?
          <LinkList items={adminNavigation.pages} itemComponent={MainNavItem} pageId={pageId} /> :
          <LinkList items={navigation.pages} itemComponent={MainNavItem} pageId={pageId} />
        }
      </Nav>
    )
  }
}

export default Navigation
