import React from 'react'
import Parse from 'parse'

import Box from '../layout/Box'

import MainHeading from '../style/text/MainHeading'
import SubHeading from '../style/text/SubHeading'


import TableTh from "../style/elements/TableTh";
import TableTd from "../style/elements/TableTd";

export class DonationReceipt extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      donation: null
    }


  }


  componentDidMount() {

    const {uuid} = this.props.match.params;

    Parse.Cloud.run('getDonationById', {
      id: uuid
    }).then((donation) => {
      this.setState({donation: donation})
    });

    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }


  render() {

    const {
      donation
    } = this.state


    if (donation) {

      return (
        <Box>
          <MainHeading>Receipt from {donation.get('organization').get('name')}</MainHeading>
          <SubHeading>Receipt #{donation.id}</SubHeading>

          <table>
            <thead>
            <tr>
              <TableTh>Amount Paid</TableTh>
              <TableTh>Date Paid</TableTh>
              <TableTh>Payment Method</TableTh>
            </tr>
            </thead>
            <tbody>
            <tr>
              <TableTd>${donation.get('amount')}</TableTd>
              <TableTd>{donation.get('donationDate').toString('M/dd/yyyy')}</TableTd>
              <TableTd>{donation.get('cardBrand')} - {donation.get('cardLastFour')}</TableTd>
            </tr>
            </tbody>
          </table>
          <SubHeading>Summary</SubHeading>
          <table>
            <tbody>
            <tr>
              <TableTd>Donation to {donation.get('organization').get('name')}</TableTd>
              <TableTd>${donation.get('amount')}</TableTd>
            </tr>
            </tbody>
          </table>
        </Box>
      )
    } else {
      return (
        <Box>
          <MainHeading>Loading...</MainHeading>
        </Box>
      )
    }
  }
}

export default DonationReceipt
