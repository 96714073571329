import React from 'react'
import Box from '../../layout/Box'

import MainHeading from '../../style/text/MainHeading'
import {ManagerComponent} from "./ManagerComponent";
import SubHeading from "../../style/text/SubHeading";
import * as _ from "lodash";
import * as moment from "moment";
import {fetchDonationsForOrganization} from "./ManagerApi";
import Text from "givapp-design/src/components/Text";
import Table from "givapp-design/src/components/Table";
import TableRow from "givapp-design/src/components/TableRow";
import TableData from "givapp-design/src/components/TableData";
import {rem} from "givapp-design/src/lib/tools";
import {Button, IconOneTime, IconRecurring, IconSpareChange, TableHead} from "givapp-design";
import {Link} from 'react-router-dom'
import LoadingCard from "./components/LoadingCard";
import {ThemedContent} from "./components/ThemedContent";
import styled from "@emotion/styled";
import {IconCheck} from "givapp-design/src/components/icons";
import Flex from "givapp-design/src/components/Flex";
import Input from "givapp-design/src/components/Input";
import Select from "givapp-design/src/components/Select";
import NumberFormat from "react-number-format";
import Parse from "parse";
import Modal from "react-modal";
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import * as space from "givapp-design/src/tokens/space";
import * as border from "givapp-design/src/tokens/border";
import * as typography from "givapp-design/src/tokens/typography";
import {currency} from "../../style/text/Currency";
import shortid from "shortid";
import {exportTableToCSV} from "../../../lib/tools";

const TableControls = styled(Flex)`
  background-color: #F3F5F5;
  border-top: 1px solid #DCE0E2;
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};


const DateRangeElement = styled(Box)`
  /* Stripe Input Helper */

  .react-daterange-picker {
    width: 100%;
    height: ${space.s6};
    font-family: ${typography.fontFamily};
    background-color: ${({theme}) => theme.color.white};
    color: ${({theme}) => theme.color.black1};

    .react-daterange-picker__wrapper {
      border-width: ${border.borderWidth};
      border-style: ${border.borderStyleDefault};
      border-color: ${({theme}) => theme.color.gray3};
      border-radius: ${border.borderRadiusSm};

      .react-daterange-picker__inputGroup {
        text-align: center;
      }
    }


    .react-calendar {
      border-width: ${border.borderWidth};
      border-style: ${border.borderStyleDefault};
      border-color: ${({theme}) => theme.color.gray3};

      border-radius: ${border.borderRadiusSm};

      .react-calendar__tile--active {
        background-color: ${({theme}) => theme.color.classicBlue3};
      }

      .react-calendar__tile--now {
        background-color: ${({theme}) => theme.color.gray3};

      }
    }

    &.react-daterange-picker--open {
      .react-daterange-picker__wrapper {
        color: ${({theme}) => theme.color.darkGray5};
        border-color: ${({theme}) => theme.color.classicBlue3};
      }
    }
  }
`

export const dateRangeOptions = [
  {
    label: 'Today', value: 'TODAY', dates: function () {
      return {
        fromDate: moment().subtract(1, 'day').toISOString(),
        toDate: moment().toISOString()
      }
    }
  },
  {
    label: 'Yesterday', value: 'YESTERDAY',
    dates: function () {
      return {
        fromDate: moment().subtract(2, 'day').toISOString(),
        toDate: moment().subtract(1, 'day').toISOString()
      }
    }
  },
  {
    label: 'This Week', value: 'THIS_WEEK',
    dates: function () {
      return {
        fromDate: moment().subtract(7, 'day').toISOString(),
        toDate: moment().toISOString()
      }
    }
  },
  {
    label: 'Last Week', value: 'LAST_WEEK',
    dates: function () {
      return {
        fromDate: moment().subtract(14, 'day').toISOString(),
        toDate: moment().subtract(7, 'day').toISOString()
      }
    }
  },
  {
    label: 'This Month', value: 'THIS_MONTH',
    dates: function () {
      return {
        fromDate: moment().startOf('month').toISOString(),
        toDate: moment().endOf('month').toISOString()
      }
    }
  },
  {
    label: 'Last Month', value: 'LAST_MONTH',
    dates: function () {
      return {
        fromDate: moment().subtract(1, 'month').startOf('month').toISOString(),
        toDate: moment().subtract(1, 'month').endOf('month').toISOString()
      }
    }
  },
  {
    label: 'Year To Date', value: 'YEAR_TO_DATE',
    dates: function () {
      return {
        fromDate: moment().startOf('day').startOf('year').toISOString(),
        toDate: moment().endOf('week').toISOString()
      }
    }
  },
  {
    label: 'Custom', value: 'CUSTOM',
    dates: function () {
      return {}
    }
  }
]

export const donationTypeOptions = [
  {label: 'Recurring', value: 'Recurring'},
  {label: 'Spare Change', value: 'Spare Change'},
  {label: 'One-Time', value: 'One Time'},
]

export class Donations extends ManagerComponent {


  constructor() {
    super();

    this.prev = this.prev.bind(this)
    this.next = this.next.bind(this)
  }

  componentDidMount() {
    super.componentDidMount();

    const {setPageId} = this.props
    setPageId('donations')

    this.setState(_.extend({
      donations: [],
      loading: true,
      page: 0,
      size: 10,
      total: 0,
      showDateRange: false
    }, this.state), () => {
      this.refreshDonations();

    });
  }

  refreshDonations = _.debounce((page) => {
    this.setState({
      loading: true,
      page: page?page:0
    }, () => {
      let params = {
        page: this.state.page,
        size: this.state.size
      };
      if (this.state.subaccount) {
        params.subaccountId = this.state.subaccount;
      }
      if (this.state.dateRange && this.state.dateRange !== 'CUSTOM') {
        params = {
          ...(_.find(dateRangeOptions, (row) => {
            return row.value === this.state.dateRange
          }).dates()),
          ...params
        };
      } else if (this.state.dateRange === 'CUSTOM' && this.state.customDateRange) {
        params = {
          ...(this.state.customDateRange),
          ...params
        };

      }
      if (this.state.donationType) {
        params.donationType = this.state.donationType
      }
      if (this.state.filter && this.state.filter != '') {
        params.filter = this.state.filter;
      }
      fetchDonationsForOrganization(this.props.organization.id, params).then((results) => {
        this.state.donations = results.data;
        this.state.total = results.total;
        this.state.loading = false;
        this.setState(this.state);
      });
    });
  }, 500);

  prev() {
    if (this.state.page > 0) {
      this.state.page = this.state.page - 1;
      this.refreshDonations(this.state.page);
    }
  }

  next() {
    if (((this.state.page + 1) * this.state.size) < this.state.total) {
      this.state.page = this.state.page + 1;
      this.refreshDonations(this.state.page);
    }
  }

  selectDateRange = (newValue) => {
    console.log('selectDateRange', newValue);

    this.setState({
      showDateRange: newValue ? newValue.value === 'CUSTOM' : false,
      dateRange: newValue ? newValue.value : null
    }, () => {
      this.refreshDonations();
    });
  };

  selectSpecificDates = (newValue) => {
    console.log('selectSpecificDates', newValue);
    this.setState({
      showDateRange: true,
      dateRange: 'CUSTOM',
      customDateRange: newValue ? {
        fromDate: newValue[0],
        toDate: newValue[1]
      } : null
    }, () => {
      this.refreshDonations();
    });
  }

  selectDonationType = (newValue) => {
    console.log('selectDonationType', newValue);

    this.setState({
      donationType: newValue ? newValue.value : null
    }, () => {
      this.refreshDonations();
    });
  };

  selectCampaign = (newValue) => {
    console.log('selectCampaign', newValue);
    this.setState({
      subaccount: newValue ? newValue.value : null
    }, () => {
      this.refreshDonations();
    });
  };

  filterDonations = (event) => {
    console.log('filterDonations', event, event.target.value);
    this.setState({
      filter: event.target.value ? event.target.value : null
    }, () => {
      this.refreshDonations();
    });
  }

  startRefund(event, donation) {
    this.setState({
      refundDonation: donation,
      modalIsOpen: true
    })


  }


  afterOpenModal() {

  }

  closeModal() {
    this.setState({
      refundDonation: null,
      modalIsOpen: false,
      refundError: null
    })
  }

  refund(event, donation) {
    Parse.Cloud.run('refund', {
      donationId: donation.id
    }).then((result) => {
      donation.refunded = true;
      this.setState({
        donorDonations: this.state.donorDonations,
        modalIsOpen: false
      });
    }, (error) => {
      this.setState({
        donorDonations: this.state.donorDonations,
        modalIsOpen: true,
        refundError: error
      });
    });
  }


  prepareDownloadCSV(event) {
    event.preventDefault()

    const {organization} = this.state

    let params = {
      page: 0,
      size: this.state.total
    };

    if (this.state.subaccount) {
      params.subaccountId = this.state.subaccount;
    }
    if (this.state.dateRange && this.state.dateRange !== 'CUSTOM') {
      params = {
        ...(_.find(dateRangeOptions, (row) => {
          return row.value === this.state.dateRange
        }).dates()),
        ...params
      };
    } else if (this.state.dateRange === 'CUSTOM' && this.state.customDateRange) {
      params = {
        ...(this.state.customDateRange),
        ...params
      };

    }
    if (this.state.donationType) {
      params.donationType = this.state.donationType
    }
    if (this.state.filter && this.state.filter != '') {
      params.filter = this.state.filter;
    }

    fetchDonationsForOrganization(this.props.organization.id, params).then((results) => {
      this.state.allDonations = results.data;
      this.setState(this.state, () => {
        exportTableToCSV('table-for-export', `${organization.name}-FinancialStatement.csv`)
      });
    });


  }


  render() {
    const {
      organization, subaccounts, donations, filter, total, loading, page, size,
      modalIsOpen,
      refundDonation,
      refundError,
      showDateRange,
      allDonations
    } = this.state;

    let customDateRange = this.state.customDateRange;


    const afterOpenModal = this.afterOpenModal;
    const closeModal = this.closeModal;

    if (!customDateRange) {
      customDateRange = [new Date(), new Date()]
    }

    const campaignOptions = _.map(subaccounts, (subaccount) => {
      return {
        label: subaccount.name,
        value: subaccount.id
      }
    });

    const pages = total / size;

    const refundCutoffDate = moment().subtract(30, 'days').toDate();

    let {selectedTheme} = this.props;
    return (
      <React.Fragment>

        <ThemedContent p={rem(10)} theme={selectedTheme}>
          {!!loading == true &&
          <LoadingCard>Loading...</LoadingCard>
          }
          {!!organization &&
          <React.Fragment>
            <MainHeading>{organization.name} Donations</MainHeading>
            <SubHeading>{total} donations found</SubHeading>
            {!!!donations.length &&
            <Box>
              <Text>No donations yet.</Text>
            </Box>
            }
            <Box width={rem(200)}>
              <Button onClick={(e) => this.prepareDownloadCSV(e)}>Download CSV</Button>
            </Box>

            <TableControls w={1.0} flexDirection={'row'} justifyContent={'space-between'} alignContent={'space-between'}
                           p={rem(4)}>
              <Box w={[0.3]}>
                <Input small isSearch theme={selectedTheme} placeholder={'Quick filter'}
                       value={filter}
                       onChange={(e) => this.filterDonations(e)}
                ></Input>
              </Box>
              <Box w={[0.5]}>
                <Flex flexDirection={'row'} justifyContent={'space-between'}>
                  <Box w={[0.5]}>
                    <Flex flexDirection={'column'} justifyContent={'space-around'}>
                      <Box w={[1]}>
                        <Select
                          clearable
                          small
                          options={dateRangeOptions}
                          placeholder="Date Range"
                          onChange={selected => this.selectDateRange(selected)}
                        />
                      </Box>
                      {!!showDateRange &&
                      <DateRangeElement w={[1]} mt={rem(5)}>
                        <DateRangePicker
                          onChange={selected => this.selectSpecificDates(selected)}
                          value={[customDateRange.fromDate, customDateRange.toDate]}
                        /></DateRangeElement>
                      }
                    </Flex>
                  </Box>
                  <Box w={[0.5]}>
                    <Flex flexDirection={'row'} justifyContent={'space-between'}>
                      <Box w={[0.5]} ml={rem(5)}>
                        <Select
                          clearable
                          small
                          options={donationTypeOptions}
                          placeholder="Donation Type"
                          onChange={selected => this.selectDonationType(selected)}
                        />
                      </Box>
                      <Box w={[0.5]} ml={rem(5)}>
                        <Select
                          clearable
                          small
                          options={campaignOptions}
                          placeholder="Campaign"
                          onChange={selected => this.selectCampaign(selected)}
                        />
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </TableControls>
            {!!donations.length > 0 &&
            <Table fixed>
              <thead>

              <TableRow>
                <TableHead>
                  Date
                </TableHead>
                <TableHead>
                  Donor
                </TableHead>
                <TableHead>
                  Campaign
                </TableHead>
                <TableHead center>
                  Amount
                </TableHead>
                <TableHead center>
                  Cover Fees
                </TableHead>
                <TableHead center>
                  Type
                </TableHead>
                <TableHead center>
                  Gross
                </TableHead>
                <TableHead center>
                  Stripe Fee
                </TableHead>
                <TableHead center>
                  GivApp Fee
                </TableHead>
                <TableHead center>
                  Net
                </TableHead>
                <TableHead center>Refund</TableHead>
              </TableRow>
              </thead>
              <tbody>
              {donations.map(donation => (

                <TableRow key={donation.id}>
                  <TableData>{donation.donationDate.toLocaleDateString()}</TableData>
                  <TableData>
                    {!!donation.user &&
                    <Text noUnderline>
                      <Link
                        to={`/donors/${donation.user.id}`}>{donation.user.get('firstName')} {donation.user.get('lastName')}</Link>
                    </Text>
                    }
                  </TableData>
                  <TableData>
                    <Text>{donation.organization.get('name')}</Text>
                  </TableData>
                  <TableData center>
                    {currency(donation.amount)}
                  </TableData>
                  <TableData center>
                    {!!donation.coverTransactionFees &&
                    <IconCheck size={18} color={selectedTheme.positive1}/>
                    }
                  </TableData>
                  <TableData width={32} center>
                    <Box mt={rem(2)}>
                      <DonationTypeIcon type={donation.donationType} theme={selectedTheme}></DonationTypeIcon>
                    </Box>
                  </TableData>
                  <TableData center>{currency(donation.grossAmount)}</TableData>
                  <TableData center>{currency(donation.stripeFee)}</TableData>
                  <TableData center>{currency(donation.givappFee)}</TableData>
                  <TableData center>{currency(donation.netAmount)}</TableData>

                  <TableData center>
                    {!!donation.refunded &&
                    <Text center>Refunded</Text>
                    }
                    {!donation.refunded && donation.donationDate > refundCutoffDate &&
                    <Button small onClick={(e) => this.startRefund(e, donation)}>Refund</Button>
                    }
                  </TableData>
                </TableRow>

              ))}

              </tbody>
            </Table>
            }
            {!!refundDonation &&
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={modalStyles}
              contentLabel="Are you sure you want to refund?"
            >
              <Flex mt={4} flexDirection={['column']}>
                <Box width={[1 / 1]}>
                  <SubHeading>Process Refund</SubHeading>
                  <Box mt={4}>
                    <Text>Refund ${refundDonation.amount} (${refundDonation.grossAmount} total)?</Text>
                  </Box>
                </Box>
                <Flex mt={4} flexDirection={['row']} justifyContent="space-between" alignItems={'center'}>
                  <Box width={[1, .49, .49]}>
                    <Button small onClick={(e) => this.refund(e, refundDonation)}>Yes, process refund</Button>
                  </Box>
                  <Box width={[1, .49, .49]}>
                    <Button small onClick={(e) => this.closeModal(e)}>Cancel</Button>
                  </Box>
                </Flex>
              </Flex>
            </Modal>

            }
            {!!refundError &&
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={modalStyles}
              contentLabel="Error processing refund"
            >
              <Flex mt={4} flexDirection={['column']}>
                <Box width={[1 / 1]}>
                  <SubHeading>Process Refund Failed!</SubHeading>
                  <Box mt={4}>
                    <Text>Refund ${refundDonation.amount} (${refundDonation.grossAmount} total) failed.</Text>
                  </Box>
                </Box>
                <Box mt={4}>
                  <Text>
                    {refundError.error}
                  </Text>
                </Box>
                <Box mt={4}>
                  <Button small onClick={(e) => this.closeModal(e)}>Close</Button>
                </Box>
              </Flex>
            </Modal>
            }
            <Box>
              <Text>
                <a onClick={(e) => this.prev(e)}>Prev</a>
                &nbsp;
                <a onClick={(e) => this.next(e)}>Next</a>
                &nbsp;
                Page {page + 1} of
                &nbsp;
                {Math.round(pages)}
              </Text>
            </Box>
          </React.Fragment>
          }
        </ThemedContent>
        {/* Hidden table for CSV Download */}

        {loading == false && organization && allDonations &&
        <table id="table-for-export" style={{display: 'none'}}>
          <thead>
          <tr>
            <th>Donation Date</th>
            <th>Organization</th>
            <th>Name</th>
            <th>Username</th>
            <th>Amount</th>
            <th>Cover Fees</th>
            <th>Type</th>
            <th>Gross</th>
            <th>Stripe Fee</th>
            <th>GivApp Fee</th>
            <th>Net</th>
          </tr>
          </thead>

          <tbody>
          {allDonations.map(donation => (
            <tr key={shortid.generate()}>
              <td>{donation.donationDate.toString('M/dd/yyyy')}</td>
              <td>{donation.organization.get('name')}</td>
              <td>
                {donation.user &&
                <Text>{donation.user.get('firstName')} {donation.user.get('lastName')}</Text>
                }
              </td>
              <td>
                {donation.user &&
                <Text>{donation.user.get('username')}</Text>
                }
              </td>
              <td><NumberFormat
                value={donation.amount}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={'text'}
              /></td>
              <td>{donation.coverTransactionFees === true ? '✓' : ''}</td>
              <td>{donation.type}</td>
              <td>
                <NumberFormat
                  value={donation.grossAmount}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                /></td>
              <td>
                <NumberFormat
                  value={donation.stripeFee}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
              <td>
                <NumberFormat
                  value={donation.givappFee}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
              <td>
                <NumberFormat
                  value={donation.netAmount}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
        }
      </React.Fragment>
    )
  }

}

const DonationTypeIcon = ({type, theme}
) => {
  if (type === 'Spare Change') {
    return <IconSpareChange size={18} color={theme.color.brightGold}/>
  } else if (type === 'One Time') {
    return <IconOneTime size={18} color={theme.color.classicBlue5}/>
  } else if (type === 'Recurring') {
    return <IconRecurring size={18} color={theme.color.berry}/>
  } else {
    return <Text center>?</Text>
  }
}
export default Donations
