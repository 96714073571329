import React from 'react'
import Flex from "givapp-design/src/components/Flex";
import Box from "givapp-design/src/components/Box";
import Text from "givapp-design/src/components/Text";
import Table from "givapp-design/src/components/Table";
import TableRow from "givapp-design/src/components/TableRow";
import TableData from "givapp-design/src/components/TableData";
import {rem} from "givapp-design/src/lib/tools";
import Card from "givapp-design/src/components/Card";
import Control from "givapp-design/src/components/Control";
import {
  IconDown,
  IconOneTime,
  IconRecurring,
  IconSpareChange,
  IconUp
} from "givapp-design/src/components/icons";
import * as numeral from "numeral";
import Select from "givapp-design/src/components/Select";
import {ManagerComponent} from "./ManagerComponent";
import * as _ from 'lodash';
import Button from "givapp-design/src/components/Button";
import styled from "@emotion/styled";
import * as moment from 'moment';
import {
  fetchOrganizationSummary,
  fetchDonationsForOrganization,
  fetchDonorsForOrganization,
  fetchSubaccountSummaries,
  fetchPayoutsForOrganization
} from "./ManagerApi";
import {currency} from "../../style/text/Currency";
import {Link} from "react-router-dom";
import LoadingCard from "./components/LoadingCard";

const DashboardBody = styled(Flex)`
  border-top: 1px solid ${props => props.theme.color.gray1};
  background-color: rgb(250, 252, 252);
  min-height: calc(100vh - 3.5rem - 6.875rem) - 103px;
`

export class Dashboard extends ManagerComponent {
  constructor() {
    super();


    this.selectDonationType = this.selectDonationType.bind(this)
    this.selectDonorType = this.selectDonorType.bind(this)
    this.selectCampaignType = this.selectCampaignType.bind(this)

  }

  selectDonationType = this.buildSelector('donations', 'recent', 'largest', (sortBy) => {
    console.log('selectDonationType', sortBy);
    fetchDonationsForOrganization(this.state.organization.id, {sortField: sortBy === 'recent' ? 'donationDate' : 'amount'}).then((results) => {
      this.state.donations[sortBy] = results.data;
      this.setState(this.state);
    });
  });

  selectDonorType = this.buildSelector('donors', 'new', 'amount', (sortBy) => {
    console.log('selectDonorType', sortBy);
    fetchDonorsForOrganization(this.state.organization.id, {sortField: sortBy === 'new' ? 'assignmentDate' : 'amount'}).then((results) => {
      this.state.donors[sortBy] = results.data;
      this.setState(this.state);
    })
  });

  selectCampaignType = this.buildSelector('campaigns', 'ending', 'amount', (sortBy) => {
    console.log('selectCampaignType', sortBy);
    fetchSubaccountSummaries(this.state.organization.id, {sortField: sortBy === 'ending' ? 'expirationDate' : 'amount'}).then((results) => {
      this.state.campaigns[sortBy] = results.data;
      this.setState(this.state);
    })
  });

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('Dashboard.componentDidUpdate', prevProps, prevState, snapshot);
  }

  componentDidMount() {
    super.componentDidMount();


    console.log('Dashboard.componentDidMount:begin');
    const {setPageId} = this.props
    setPageId('dashboard');


    this.setState(_.extend({
      loading: false,
      organization: this.props.organization,
      year: moment().year(), //selected year
      month: moment().month(),//selected month
      monthName: moment().localeData().months(moment()),
      totals: {},
      donations: {
        selected: 'recent',
        recent: [],
        largest: []
      },
      donors: {
        selected: 'amount',
        new: [],
        amount: []
      },
      payouts: {
        all: []
      },
      campaigns: {
        selected: 'amount',
        ending: [],
        amount: []
      },
      invoices: {
        selected: 'outstanding',
        outstanding: [],
        past: []
      },
      ytdGiving: 0,
      ytdGivingChange: 0,
      ytdAppGiving: 0,
      ytdWebGiving: 0,
      thisMonthGiving: 0,
      thisMonthGivingChange: 0,
      ytdNewDonors: 0,
      ytdNewDonorsChange: 0
    }, this.state), () => {
      fetchOrganizationSummary(this.state.organization.id, this.state.year, this.state.month).then((headerTotals) => {
        let totalDonations = _.find(headerTotals.donations.annually, {year: this.state.year})
        if (!this.state.totals[this.state.year]) {
          this.state.totals[this.state.year] = {
            totalDonations: 0,
            campaignDonations: 0,
            monthlyRecurringDonations: 0,
            totalDonors: 0
          };
        }

        this.state.totals[this.state.year].totalDonations = totalDonations ? totalDonations.total : 0;

        let ytdGiving = _.find(headerTotals.donations.annually, {year: this.state.year});
        this.state.ytdGiving = ytdGiving ? ytdGiving.total : 0;

        let totalDonors = _.find(headerTotals.donors.annually, {year: this.state.year});
        this.state.totals[this.state.year].totalDonors = totalDonors ? totalDonors.count : 0;

        let ytdNewDonors = _.find(headerTotals.donors.annually, {year: this.state.year});
        this.state.ytdNewDonors = ytdNewDonors ? ytdNewDonors.count : 0;

        let campaignDonations = _.find(headerTotals.campaigns.annually, {year: this.state.year});
        if (campaignDonations) {
          this.state.totals[this.state.year].campaignDonations = campaignDonations.total;
        }
        this.state.totals[this.state.year].monthlyRecurringDonations = headerTotals.recurring.total;

        this.state.ytdAppGiving = _.find(headerTotals.donations.totals_by_platform['MOBILE'], {year: this.state.year});
        this.state.ytdWebGiving = _.find(headerTotals.donations.totals_by_platform['WEB'], {year: this.state.year});

        let thisMonthGiving = _.find(headerTotals.donations.monthly, {
          year: this.state.year,
          month: this.state.month + 1
        });
        this.state.thisMonthGiving = thisMonthGiving ? thisMonthGiving.total : 0;


        let lastMonthGiving = _.find(headerTotals.donations.monthly, {
          year: moment().subtract(1, 'month').year(),
          month: moment().subtract(1, 'month').month() + 1
        });
        this.state.lastMonthGiving = lastMonthGiving ? lastMonthGiving.total : 0;


        this.state.loading = false;
        this.setState(this.state, () => {
          let promises = [];
          promises.push(fetchDonationsForOrganization(this.state.organization.id));
          promises.push(fetchDonorsForOrganization(this.state.organization.id, {sortField: 'amount'}));
          promises.push(fetchSubaccountSummaries(this.state.organization.id));
          promises.push(fetchPayoutsForOrganization(this.state.organization.id));

          Promise.all(promises).then(value => {
            console.log('All promises resolved', value);
            let donationResults = value[0].data;
            let donorResults = value[1].data;
            let campaignResults = value[2].data;
            let payoutResults = value[3].data;
            this.state.donations.recent = donationResults;
            this.state.donors.amount = donorResults;
            this.state.campaigns.amount = campaignResults;
            this.state.payouts = {'all': payoutResults};
            this.setState(this.state);
          });
        });


        //TODO something with header totals
        // fetchRecentDonations();
        // fetchNewDonors();
        // fetchMobileMessagesSent();
        // fetchPayouts();
        // fetchSubAccountSummaries();
        // ////fetchOutstandingInvoices();
        // fetchDonationSummariesForThisMonth();
      })
    });


    //  this.forceUpdate();

    console.log('Dashboard.componentDidMount:end');

  }

  componentWillUnMount() {
    console.log('Dashboard.componentDidUnmount');
  }

  buildSelector(type, fromType, toType, callback) {
    return ($event) => {

      $event.preventDefault();
      let id = $event.target.getAttribute('data-id');

      callback(id);
      this.setState((prevState) => {
          let obj = {};
          obj[type] = {
            ...prevState[type],
            selected: id
          };
          return obj
        }
      );
    }
  }


  render() {
    console.log('Dashboard.render')
    let {selectedTheme} = this.props;

    let {
      year,
      month,
      monthName,
      totals,
      ytdGiving,
      ytdGivingChange,
      ytdAppGiving,
      ytdWebGiving,
      thisMonthGiving,
      lastMonthGiving,
      ytdNewDonors,
      ytdNewDonorsChange
    } = this.state;

    const thisMonthGivingChange = thisMonthGiving - lastMonthGiving;

    if (typeof this.state.loading === 'undefined' || this.state.loading) {
      return (<Flex flexDirection={'column'} width={[1]} height={['100%']}
                    justifyContent={'center'}>
        <LoadingCard></LoadingCard>
      </Flex>)
    } else {
      let donations = this.state.donations[this.state.donations.selected];
      let donors = this.state.donors[this.state.donors.selected];
      let payouts = this.state.payouts['all'];
      let campaigns = this.state.campaigns[this.state.campaigns.selected];
      let invoices = this.state.invoices[this.state.invoices.selected];

      console.log('donations', this.state.donations.selected, donations.length);

      //let downIcon = <IconDown size="48" color={selectedTheme.color.negative3}/>;
      //let upIcon = <IconUp size="48" color={selectedTheme.color.positive3}/>;

      return (
        <Flex flexDirection={'column'}>
          {!!(typeof this.state.loading === 'undefined' || this.state.loading) &&
          <Flex flexDirection={'column'} width={[1]} height={['100%']}
                justifyContent={'center'}>
            <LoadingCard></LoadingCard>
          </Flex>
          }
          <Flex flexDirection={'row'} justifyContent={'space-between'} flexWrap={'nowrap'} mt={rem(10)} mb={rem(10)}
                p={rem(10)}>
            <Box width={[.25]} p={rem(10)}>
              <Text marketingTitle blue>{currency(totals[year].totalDonations)}</Text>
              <Text>Total Donations {year}</Text>
              {!!totals[year - 1] &&
              <Flex>
                <Text>
                  <IconUp size="24" color={selectedTheme.color.positive3}/>
                </Text>
                <Text>{currency(totals[year - 1].totalDonations)}</Text>
                <Text>YOY</Text>
              </Flex>
              }
            </Box>
            <Box width={[.25]} p={rem(10)}>
              <Text marketingTitle blue>{currency(totals[year].campaignDonations)}</Text>
              <Text>Campaign Giving {year}</Text>
              {!!totals[year - 1] &&
              <Flex>
                <Text>
                  <IconUp size="24" color={selectedTheme.color.positive3}/>
                </Text>
                <Text>{currency(totals[year - 1].campaignDonations)}</Text>
                <Text>YOY</Text>
              </Flex>
              }
            </Box>
            <Box width={[.25]} p={rem(10)}>
              <Text marketingTitle berry>{currency(totals[year].monthlyRecurringDonations)}</Text>
              <Text>Expected Monthly Recurring</Text>
              {!!totals[year - 1] &&
              <Flex>
                <Text>
                  <IconUp size="24" color={selectedTheme.color.positive3}/>
                </Text>
                <Text>{currency(totals[year - 1].monthlyRecurringDonations)}</Text>
                <Text>YOY</Text>
              </Flex>
              }
            </Box>
            <Box width={[.25]} p={rem(10)}>
              <Text marketingTitle gray>{totals[year].totalDonors}</Text>
              <Text>Total Donors</Text>
              {!!totals[year - 1] &&
              <Flex>
                <IconUp size="24" color={selectedTheme.color.positive3}/>
                <Text>{currency(totals[year - 1].totalDonors)}</Text>
                <Text>YOY</Text>
              </Flex>
              }
            </Box>
          </Flex>
          <DashboardBody flexDirection={'row'} pr={6} theme={selectedTheme}>

            <Flex flexDirection={'column'} width={[.4]}>

              <DonationsCard theme={selectedTheme} donations={donations} onClick={this.selectDonationType}
                             activeId={this.state.donations.selected}></DonationsCard>
              <DonorsCard theme={selectedTheme} donors={donors} onClick={this.selectDonorType}
                          activeId={this.state.donors.selected}></DonorsCard>
              {/*<MessagesCard></MessagesCard>*/}

            </Flex>
            <Flex flexDirection={'column'} width={[.4]}>
               <PayoutsCard theme={selectedTheme} payouts={payouts}></PayoutsCard>
              <CampaignsCard theme={selectedTheme}
                             campaigns={campaigns}
                             onClick={this.selectCampaignType}
                             activeId={this.state.campaigns.selected}>Campaigns</CampaignsCard>
              {/* <InvoicesCard theme={selectedTheme} invoices={invoices}></InvoicesCard> */}
            </Flex>
            <Flex flexDirection={'column'} width={[.2]}>
              {/* <POSCard></POSCard> */}
              {/*
              <Box mt={5} ml={5}>
                <Card>
                  <Text>Online Giving</Text>
                </Card>
              </Box>
              */}
              <IconCard theme={selectedTheme}
                        title={currency(ytdGiving)}
                        label={'Ytd Total Giving'}
                        change={ytdGivingChange}
                        timeframe={'year'}
                        icon={false}
              >
                <Box>
                  <Text small medium
                        textColor={selectedTheme.color.black1}>{currency(ytdAppGiving ? ytdAppGiving.total : 0)} App</Text>
                  <Text mt={rem(2)} small medium
                        textColor={selectedTheme.color.black1}>{currency(ytdWebGiving ? ytdWebGiving.total : 0)} Online</Text>
                </Box>
              </IconCard>
              <IconCard theme={selectedTheme}
                        title={currency(thisMonthGiving)}
                        label={monthName + ' Giving'}
                        change={thisMonthGivingChange}
                        timeframe={'month'}
                        icon={false}></IconCard>
              <IconCard theme={selectedTheme}
                        title={currency(totals[year].monthlyRecurringDonations)}
                        label={'Expected Monthly Recurring'}
                        icon={<IconRecurring size="48" color={selectedTheme.color.berry}/>}></IconCard>
              <IconCard theme={selectedTheme}
                        title={ytdNewDonors.toString()}
                        label={'YTD New Donors'}
                        change={ytdNewDonorsChange}
                        timeframe={'year'}
                        icon={false}></IconCard>
            </Flex>
          </DashboardBody>
        </Flex>
      )

    }
  }
}


const
  DonationsCard = ({theme, donations, onClick, activeId}) => {
    console.log(donations);
    return (
      <Box mt={5} ml={5}>
        <Card
          title="Donations"
          control={<Control options={[
            {id: 'recent', label: 'Recent'},
            {id: 'largest', label: 'Largest'}
          ]} activeId={activeId} onClick={onClick}/>} //onClick
          moreLink={donations.length ? '/donations' : false}
        >

          {!!!donations.length &&
          <Box>
            <Text>No donations yet.</Text>
          </Box>
          }
          {!!donations.length > 0 &&
          <Table fixed>
            <tbody>
            {donations.map(donation => (
              <TableRow key={donation.id}>
                <TableData noBorder width={180}>
                  <Text as="strong" block small medium hoverOnly noUnderline>
                    {donation.user ?
                      <Link to={'/donors/' + donation.user.id}>
                        {donation.user.get('firstName')} {donation.user.get('lastName')}
                      </Link> :
                      "Unknown User?"}
                  </Text>
                  <Text as="span" block meta gray>{donation.organization.get('name')}</Text>
                </TableData>
                <TableData noBorder black medium>{currency(donation.amount)}</TableData>
                <TableData noBorder width={32} center>
                  <Box mt={rem(2)}>
                    <DonationTypeIcon type={donation.donationType} theme={theme}></DonationTypeIcon>
                  </Box>
                </TableData>
                <TableData noBorder meta gray width={60} right>{donation.donationDate.toLocaleDateString()}</TableData>
              </TableRow>
            ))}
            </tbody>
          </Table>
          }
        </Card>
      </Box>
    )
  }

const
  DonorsCard = ({theme, donors, onClick, activeId}) => {
    return (
      <Box mt={5} ml={5}>
        <Card
          title="Donors"
          control={<Control options={[
            {id: 'new', label: 'New'},
            {id: 'amount', label: 'Amount'}
          ]} activeId={activeId} onClick={onClick}/>} //onClick
          moreLink={donors.length ? '/donors' : false}
        >

          {!!!donors.length &&
          <Box>
            <Text>No donors yet.</Text>
          </Box>
          }
          {!!donors.length > 0 &&
          <Table fixed>
            <tbody>
            {donors.map((donor) => (
              <TableRow key={donor.id}>
                <TableData noBorder width={180}>
                  {!!donor.user &&
                  <Text as="strong" block small medium hoverOnly noUnderline>
                    <Link
                      to={'/donors/' + donor.user.id}>{donor.user.get('firstName')} {donor.user.get('lastName')}</Link>
                  </Text>
                  }
                  <Text as="span" block meta gray>{donor.organization.get('name')}</Text>
                </TableData>
                <TableData noBorder black medium>{currency(donor.donation.get('amount'))}</TableData>
                <TableData noBorder width={32} center>
                  <Box mt={rem(2)}>
                    <DonationTypeIcon type={donor.donation.get('type')} theme={theme}/>
                  </Box>
                </TableData>
                <TableData noBorder meta gray width={60} right>{donor.date.toLocaleDateString()}</TableData>
              </TableRow>
            ))}
            </tbody>
          </Table>
          }
        </Card>
      </Box>
    )
  }

const
  PayoutsCard = ({theme, payouts, onClick}) => {
    return (
      <Box mt={5} ml={5}>
        <Card
          title="Payouts"
          moreLink={payouts.length ? '/payouts' : false}
        >
          {!!!payouts.length &&
          <Box>
            <Text>Coming soon...</Text>
          </Box>
          }
          {!!payouts.length > 0 &&
          <Table fixed>
            <tbody>
            <TableRow>
              <TableData noBorder width={180}>
                <Text as="strong" block small medium hoverOnly noUnderline><a>Pam Jones</a></Text>
                <Text as="span" block meta gray>General</Text>
              </TableData>
              <TableData noBorder black medium>$20</TableData>
              <TableData noBorder width={32} center>
                <Box mt={rem(2)}>
                  <IconSpareChange size={18} color={theme.color.gray4}/>
                </Box>
              </TableData>
              <TableData noBorder meta gray width={60} right>12/02/19</TableData>
            </TableRow>
            </tbody>
          </Table>
          }
        </Card>
      </Box>
    )
  }


const
  CampaignsCard = ({theme, campaigns, onClick, activeId}) => {
    return (
      <Box mt={5} ml={5}>
        <Card
          title="Campaigns"
          control={<Control options={[
            {id: 'ending', label: 'Ending'},
            {id: 'amount', label: 'Amount'}
          ]} activeId={activeId} onClick={onClick}/>} //onClick
          moreLink={campaigns.length ? '/campaigns' : false}
        >

          {!!!campaigns.length &&
          <Box>
            <Text>No campaigns yet.</Text>
          </Box>
          }
          {!!campaigns.length > 0 &&
          <Table fixed>
            <tbody>
            {campaigns.map((campaign) => (
              <TableRow key={campaign.objectId}>
                <TableData noBorder width={180}>
                  <Text as="strong" block small medium hoverOnly
                        noUnderline><a>{campaign.organization.get('name')}</a>
                  </Text>
                </TableData>
                <TableData noBorder black medium>{currency(campaign.total)}</TableData>
                <TableData noBorder meta gray width={60} right>
                  {campaign.organization.get('expirationDate')}
                </TableData>
              </TableRow>
            ))}
            </tbody>
          </Table>
          }
        </Card>
      </Box>
    )
  }

const
  InvoicesCard = ({theme, invoices, onClick}) => {
    return (
      <Box mt={5} ml={5}>
        <Card
          title="Invoices"
          moreLink={invoices.length ? '/invoices' : false}
        >

          {!!!invoices.length &&
          <Box>
            <Text>No donors yet.</Text>
          </Box>
          }
          {!!invoices.length > 0 &&
          <Table fixed>
            <tbody>
            <TableRow>
              <TableData noBorder width={180}>
                <Text as="strong" block small medium hoverOnly noUnderline><a>Pam Jones</a></Text>
                <Text as="span" block meta gray>General</Text>
              </TableData>
              <TableData noBorder black medium>$20</TableData>
              <TableData noBorder width={32} center>
                <Box mt={rem(2)}>
                  <IconSpareChange size={18} color={theme.color.gray4}/>
                </Box>
              </TableData>
              <TableData noBorder meta gray width={60} right>12/02/19</TableData>
            </TableRow>
            </tbody>
          </Table>
          }
        </Card>
      </Box>
    )
  }

const
  MessagesCard = ({theme, messages, engagement, onClick}) => {
    return (
      <Box mt={5} ml={5}>
        <Card
          title="Messages"
          moreLink={messages ? '/messages' : null}
          header={
            <Flex justifyContent="space-between" pb={6}>
              <Box width={1 / 1}>
                <Select small options={[
                  {label: 'Past Year', value: 'past-year'},
                  {label: '90 Days', value: 'days-90'},
                  {label: '30 Days', value: 'days-30'}
                ]} defaultValue={{label: 'Past Year', value: 'past-year'}}/>
              </Box>
              <Box ml={4}>
                <Control options={[
                  {id: 'mobile', label: 'Mobile'},
                  {id: 'email', label: 'Email'}
                ]} activeId="mobile"/>
              </Box>
            </Flex>
          }
        >
          <Box>
            {!!!messages &&
            <Box>
              <Text>No messages sent.</Text>
            </Box>
            }
            {!!messages &&
            <Box>
              <Text marketingTitle berry>{messages} Messages Sent</Text>
              <Text>{engagement} Engagement</Text>
            </Box>
            }
          </Box>
        </Card>
      </Box>
    )
  }

const
  POSCard = ({theme, machines, onClick}) => {
    return (
      <Box mt={5} ml={5}>
        <Card
          blue
        >
          <Flex>
            <Box>
              <Text>Point of Sale</Text>
            </Box>
            {!!machines &&
            <Flex>
              <Box>
                <Select small
                        placeholder={'Select POS Machine'}
                        options={[]}/>
              </Box>
              <Box>
                <Button>Launch</Button>
              </Box>
            </Flex>
            }
          </Flex>
        </Card>
      </Box>
    )
  }

const
  IconCard = ({children, theme, title, label, change, icon, timeframe}) => {
    const color = change === 0 ? theme.color.gray3 : (change > 0 ? theme.color.positive4 : theme.color.negative4);
    return (
      <Box mt={5} ml={5}>
        <Card
          small
          title={title}
          icon={icon}
          noTitleSpace
        >
          <Box mt={2}>
            <Text medium black>{label}</Text>
            {!!change &&
            <Text mt={1} meta>
              <Text as="strong" meta bold
                    textColor={color}>{change > 0 ? '+' : ''}{change}%</Text> from
              last {timeframe}</Text>
            }
            {!!children &&
            <Box mt={4} pt={3} borderTop borderSoft>
              {children}
            </Box>
            }
          </Box>
        </Card>
      </Box>
    )
  }

const DonationTypeIcon = ({type, theme}) => {
  if (type === 'Spare Change') {
    return <IconSpareChange size={18} color={theme.color.brightGold}/>
  } else if (type === 'One Time') {
    return <IconOneTime size={18} color={theme.color.classicBlue5}/>
  } else if (type === 'Recurring') {
    return <IconRecurring size={18} color={theme.color.berry}/>
  } else {
    return <Text>?</Text>
  }
}

export default Dashboard;
