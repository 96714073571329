import {rem} from '../lib/tools'
import {type, color, dimension, border} from '../designSystem'

export default `
  /*
  html {
    height: 100%
  }
  body {
    box-sizing: border-box;
    height: 100%;
    font-family: ${type.family.default};
    font-size: 16px;
    font-style: normal;
    font-weight: ${type.weight.regular};
    color: ${color.black};
    background-color: ${color.lightestGray};
    -webkit-text-size-adjust: 100%
  }
  a {
    text-decoration: none;
  }
  ::selection {
    color: ${color.white};
    background-color: ${color.blue}
  }

  .wf-loading {
    visibility: hidden;
  }
*/
  /* Fix react-select styles */
  /*
  .select-donor-for-statement { width: 100% !important; }
  .select-donor-for-statement > div { width: 100% !important; border: 0 !important; }
  .select-donor-for-statement input {
    position: relative;
    left: ${rem(2)};
    bottom: ${rem(2)};
    font-family: ${type.family.default} !important;
  }*/
  /* End Fix */
  
    
        .StripeElement {
            box-sizing: border-box;

            height: ${rem(46)};

            padding: 14px ${rem(dimension.d0)} 0px ${rem(dimension.d0)};

            line-height: ${rem(46)};
            border: 0;
            border-radius: ${rem(border.radius.sm)};
            background-color: ${color.lightestGray};

            box-shadow: none;
            -webkit-transition: box-shadow 150ms ease;
            transition: box-shadow 150ms ease;
        }

        .StripeElement--focus {
            background-color: ${color.white};
            box-shadow: inset 0 0 ${rem(2)} 0 ${color.lightGray};
            outline: none;
        }

        .StripeElement--invalid {
            border-color: #fa755a;
        }

        .StripeElement--webkit-autofill {
            background-color: #fefde5 !important;
        }
        
        .react-daterange-picker{
            border: 1px solid ${color.blue} !important;
        }

`
