import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'

import {css, Global} from '@emotion/core'
import {ThemeProvider} from 'emotion-theming'
import styled from '@emotion/styled'
import {Box, Flex, Text, theme} from 'givapp-design'
import GivAppLogo from "givapp-design/src/special/online-giving/components/GivAppLogo";
import * as border from "givapp-design/src/tokens/border";
import Card from "givapp-design/src/components/Card";


import Logo from "./components/Logo"
import Footer from './components/Footer'
import reset from './styles/reset'
import webfonts from './styles/webfonts'
//import {parseJSON} from './lib/tools'
import {becomeUser, getCurrentUser} from './lib/auth'
//
// import StartAtTop from './components/system/StartAtTop'
//
import Login from './components/pages/Login'
//
import ForgotPassword from './components/pages/ForgotPassword'
import NewPassword from './components/pages/NewPassword'
import {PasswordResetSuccess} from './components/pages/PasswordResetSuccess'
import BasicPage from './components/pages/BasicPage'
import NotFound from './components/pages/NotFound'
import MessageCard from './components/pages/organization/components/MessageCard'
//
import VerifyPayment from './components/pages/VerifyPayment'
//
import AuthenticatedRoute from './components/routes/AuthenticatedRoute'
//
// import Site from './components/structure/Site'
// import Header from './components/structure/Header'
// import Footer from './components/structure/Footer'
// import Main from './components/structure/Main'
//
// import Box from './components/layout/Box'
import MainHeading from './components/style/text/MainHeading'
// import Text from './components/style/text/Text'
//
// import theme from './theme'
import queryString from 'qs'
//
//
// // Global Styles
// import reset from './styles/reset'

import globalStyles from './styles/globalStyles'
import Parse from "./lib/parse";
import MobilePaymentDetails from "./components/pages/donate/MobilePaymentDetails";
import {DonationReceipt} from "./components/pages/DonationReceipt";
import PaymentDetails2 from "./components/pages/donate2/PaymentDetails2";
import SelectOrganization2 from "./components/pages/donate2/SelectOrganization2";
//import navigationData from "./content/main-nav.json";
//import adminNavigationData from "./content/admin-nav.json";
import Manager from "./components/pages/organization/Manager";
import DonateRoute from "./components/pages/donate3/DonateRoute";
import {Donate} from "./components/pages/donate3/Donate";

// import {ThemeProvider, injectGlobal} from 'styled-components'
//
// // eslint-disable-next-line
// import {Date} from "datejs"
//
//
// injectGlobal`
//   ${reset}
// `
//const navigation = parseJSON(navigationData)
//const adminNavigation = parseJSON(adminNavigationData)

class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      user: null,
      pageId: null
    }

    //this.setAuthState()
  }

  onSignIn = () => {
    console.log('onSignIn');

    this.setAuthState();
    Parse.Session.current().then((session) => {
        console.log("onSignIn:token", session.getSessionToken());
      }, (error) => {
        console.log("onSignIn:error", error);
      }
    );
  }

  onSignOut = () => {
    console.log('onSignOut')
    this.setState({user: null}, () => {
      getCurrentUser().then(value => {
        this.setState({user: value});
      });
    });

  }

  setAuthState = () => {
    //let currentUser = Parse.User.current()
    //console.log('setAuthState', currentUser);
    if (window.location && window.location.search) {
      const values = queryString.parse(window.location.search, {ignoreQueryPrefix: true})
      console.log("App.setAuthState:params", values);
      if (values.sessionToken) { //"r:059707afe90cc9fc7b39cae488d98382"
        becomeUser(values.sessionToken).then(restoredUser => {
          this.setState({user: restoredUser});
          console.log('App.setAuthState:became user', restoredUser);
        }, (error) => {
          console.log('App.setAuthState:become user', error);
        });
      } else {
        getCurrentUser().then(value => {
          this.setState({user: value}, () => {
            //console.log('App.setAuthState:forceUpdate');
            //this.forceUpdate();
          });
        });

      }
    } else {
      getCurrentUser().then(value => {
        this.setState({user: value}, () => {
          //console.log('App.setAuthState:forceUpdate');
          //this.forceUpdate();
        });
      });
    }
    /*
        Parse.Session.current().then((session) => {
            console.log("setAuthState:token", session.getSessionToken());
          }, (error) => {
            console.log("setAuthState:error", error);
          }
        );

     */
  }

  handleActivate = data => {
    console.log('App.handleActivate');
    const {user} = this.state
    user.set(data)
    this.setState({user})
  }

  /*
    static getDerivedStateFromProps(props, state) {
      console.log('getDerivedStateFromProps');
      this.setAuthState()
    }*/

  componentWillMount() {
    this.setAuthState()
  }

  componentDidMount() {
    //this.setAuthState()
  }

  render() {
    console.log('App:render');
    const {user} = this.state;//, pageId, pageTitle, summary} = this.state
    // const pageTitleText = !!pageTitle ? pageTitle : 'GivApp'
    // const pageDescription = !!summary ? summary : 'GivApp description goes here.'
    //
    // console.log('Render App', user);

    // return (
    //
    //   <ThemeProvider theme={theme}>
    //     {!!user &&
    //     <Router>
    //       <StartAtTop>
    //
    //         {/* Insert into <head> */}
    //         <Helmet>
    //           <title>{pageTitleText}</title>
    //           <link rel="icon" href="/givapp-favicon.png"/>
    //
    //           {/* Typekit Web App */}
    //           <link rel="stylesheet" href="https://use.typekit.net/bln6vbi.css"/>
    //
    //           <meta name="description" content={pageDescription}/>
    //         </Helmet>
    //
    //         {/* Start <body> content */}
    //         <Site>
    //           <Box>
    //             <Header {...{user, pageId}} onSignOut={this.onSignOut}/>
    //
    //             <Main>

    //             </Main>
    //           </Box>
    //
    //           <Box mt={[2, 3]}>
    //             <Footer/>
    //           </Box>
    //         </Site>
    //
    //       </StartAtTop>
    //     </Router>
    //     }
    //   </ThemeProvider>
    //
    // )
    if (!user) {
      return null;
    }
    return (
      <React.Fragment>
        <Global styles={css`
          ${webfonts} ${reset}
        `}/>

        <ThemeProvider theme={theme}>
          <Site flexDirection="column">
            <Router>
              <Switch>

                {/* Login */}
                <Route
                  exact
                  path="/login"
                  component={props => (
                    <BasicPage>
                    <Login user={user} onComplete={this.onSignIn}/>
                    </BasicPage>
                  )}
                />

                {/* Forgot Password */}
                <Route
                  exact
                  path="/forgot-password"

                  component={props => (
                    <BasicPage>
                    <ForgotPassword user={user}/>
                    </BasicPage>
                  )}
                />


                {/* Parse Server Custom Pages for Password Reset */}
                <Route
                  exact
                  path="/password-reset-success"
                  component={props => (
                    <BasicPage>
                      <PasswordResetSuccess />
                    </BasicPage>
                  )}
                />
                <Route
                  exact
                  path="/invalid-link"
                  component={props => (
                    <BasicPage>
                      <Flex alignItems={'center'} flexDirection={'column'}>
                        <Box>
                          <Text title1>Invalid Link</Text>
                        </Box>
                        <Box mt={2}>
                          <Text><p>This link is no longer valid.</p></Text>
                        </Box>
                      </Flex>
                    </BasicPage>
                  )}
                />
                <Route
                  exact
                  path="/verify-email"
                  component={props => (
                    <BasicPage>
                        <Box>
                        <Card>
                        <Text title1>Email Verified</Text>
                        <Box mt={2}>
                          <Text><p>Your email has been verified, thank you!</p></Text>
                        </Box>
                        </Card>
                        </Box>
                    </BasicPage>
                  )}
                />
                <Route
                  exact
                  path="/new-password"
                  component={props => (
                    <BasicPage>
                      <Flex alignItems={'center'} flexDirection={'column'}>
                    <Box maxWidth={400}>
                      <Logo width={10} />
                    </Box>
                    <Box>
                    <NewPassword queryParams={queryString.parse(props.location.search, {ignoreQueryPrefix: true})}/>
                    </Box>
                      </Flex>
                    </BasicPage>
                  )}
                />


                <Route
                  exact
                  path="/onetime/:uuid"
                  render={(matchProps) =>
                    <Flex flexDirection={'column'}>
                      <Body flexDirection={'column'}>
                        <Content flexDirection={'column'}>
                          <VerifyPayment
                            {...matchProps}
                            {...this.props}
                            handleMatch={this.handleMatch}
                          />
                        </Content>
                      </Body>
                    </Flex>
                  }/>

                <Route
                  exact
                  path="/recurring/:uuid"
                  render={(matchProps) =>
                    <Flex flexDirection={'column'}>
                      <Body flexDirection={'column'}>
                        <Content flexDirection={'column'}>
                          <VerifyPayment
                            {...matchProps}
                            {...this.props}
                            handleMatch={this.handleMatch}
                          />
                        </Content>
                      </Body>
                    </Flex>

                  }/>

                <Route
                  exact
                  path="/receipt/:uuid"
                  render={(matchProps) =>
                    <Flex flexDirection={'column'}>
                      <Body flexDirection={'column'}>
                        <Content flexDirection={'column'}>
                          <DonationReceipt
                            {...matchProps}
                            {...this.props}
                            user={user}
                            handleMatch={this.handleMatch}
                          />
                        </Content></Body>
                    </Flex>

                  }/>


                <DonateRoute
                  user={user}
                  path='/donate'
                  component={props => (
                    <BasicPage>
                        <Content flexDirection={'column'}>
                          <Donate
                            {...props}
                            {...this.state}
                          />
                        </Content>
                    </BasicPage>
                  )}/>

                {/* Authenticated Routes */}
                <AuthenticatedRoute
                  user={user}
                  path="/"
                  component={props => (
                    <Flex flexDirection={['column']} width={'100%'}>
                      <Flex flexDirection={['column']} width={'100%'}>
                        <Manager
                          {...props}
                          {...this.state}
                          onSignOut={this.onSignOut}
                          width={'100%'}
                        />
                      </Flex>
                      <Footer/>
                    </Flex>
                  )}
                />


                {/* 404 */}
                <Route component={NotFound}/>
              </Switch>
            </Router>

          </Site>
        </ThemeProvider>
      </React.Fragment>
    )
  }
}

const CenterCard = styled(Card)`
  width: 50%;
`

const Site = styled(Flex)`
  min-height: 100vh;
`
const Content = styled(Flex)`
  overflow-x: auto;
  height: 100%;
  width: 100%;
`
const BodyStyle = styled(Flex)`
  background-color: rgb(250, 252, 252);
`
const Body = ({props, children, ...rest}) => (<BodyStyle
  flexDirection={'column'}
  justifyContent={'center'}
  alignItems={'center'}
  alignContent={'center'}
  {...props}
  {...rest}
  height={'calc(100vh - 6.3rem)'}
>
  {children}
</BodyStyle>)

export default App
