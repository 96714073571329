import React from 'react'
import Box from '../../layout/Box'

import MainHeading from '../../style/text/MainHeading'
import SubHeading from "../../style/text/SubHeading";
import {ManagerComponent} from "./ManagerComponent";
import * as _ from "lodash";
import {fetchDonorsForOrganization} from "./ManagerApi";
import Text from "givapp-design/src/components/Text";
import Table from "givapp-design/src/components/Table";
import TableRow from "givapp-design/src/components/TableRow";
import {TableHead} from "givapp-design";
import TableData from "givapp-design/src/components/TableData";
import {Link} from "react-router-dom";
import {rem} from "givapp-design/src/lib/tools";
import LoadingCard from "./components/LoadingCard";
import {ThemedContent} from "./components/ThemedContent";
import Flex from "givapp-design/src/components/Flex";
import {currency} from "../../style/text/Currency";

export class Donors extends ManagerComponent {


  constructor() {
    super();

    this.prev = this.prev.bind(this)
    this.next = this.next.bind(this)
  }

  componentDidMount() {
    super.componentDidMount();


    const {setPageId} = this.props
    setPageId('donors')

    console.log('Donors:componentDidMount');

    this.setState(_.extend({
      donors: [],
      loading: true,
      page: 0,
      size: 10,
      total: 0
    }, this.state), () => {
      this.refreshDonors();
    });
  }

  refreshDonors() {
    this.setState({
      loading: true
    }, () => {
      fetchDonorsForOrganization(this.props.organization.id, {
        page: this.state.page,
        size: this.state.size
      }).then((results) => {
        this.state.donors = results.data;
        this.state.total = results.total;
        this.state.loading = false;
        this.setState(this.state);
      });
    });
  }


  prev() {
    if (this.state.page > 0) {
      this.state.page = this.state.page - 1;
      this.refreshDonors();
    }
  }

  next() {
    if ((this.state.page + 1 * this.state.size) < this.state.total) {
      this.state.page = this.state.page + 1;
      this.refreshDonors();
    }
  }

  render() {

    const {organization, subaccounts, donors, total, loading, page, size} = this.state;
    let {selectedTheme} = this.props;
    const pages = total / size;

    console.log('loading', loading);
    return (
      <React.Fragment>

        <ThemedContent p={rem(10)} theme={selectedTheme}>
          {!!loading == true &&
          <LoadingCard>Loading...</LoadingCard>
          }
          {!!organization &&
          <React.Fragment>
            <MainHeading>{organization.name} Donors</MainHeading>
            {!!!donors || !donors.length &&
            <Box>
              <Text>No donors yet.</Text>
            </Box>
            }
            {!!donors && donors.length > 0 &&
            <Table>
              <thead>

              <TableRow>
                <TableHead>
                  Name
                </TableHead>
                <TableHead>
                  Email
                </TableHead>
                <TableHead>
                  Donor Since
                </TableHead>
                <TableHead>
                  Total Donated
                </TableHead>
                <TableHead>
                  Total Donations
                </TableHead>
              </TableRow>
              </thead>
              <tbody>
              {donors.map(donor => (

                <TableRow key={donor.id}>
                  <TableData>
                    <Text noUnderline>
                      <Link
                        to={`/donors/${donor.user.id}`}>{donor.user.get('firstName')} {donor.user.get('lastName')}</Link>
                    </Text>
                  </TableData>
                  <TableData>
                    <Text>{donor.user.get('username')}</Text>
                  </TableData>
                  <TableData>{donor.date.toLocaleDateString()}</TableData>
                  <TableData>{donor.donated ? currency(donor.donated.total) : ''}</TableData>
                  <TableData>{donor.donated ? donor.donated.count : ''}</TableData>
                </TableRow>

              ))}

              </tbody>
            </Table>
            }
            <Box>
              <Text>
                <a onClick={(e) => this.prev(e)}>Prev</a>
                &nbsp;
                <a onClick={(e) => this.next(e)}>Next</a>
                &nbsp;
                Page {page + 1} of
                &nbsp;
                {Math.round(pages)}
              </Text>
            </Box>
          </React.Fragment>
          }
        </ThemedContent>
      </React.Fragment>
    )
  }
}

export default Donors
