import React from 'react'
import Parse from 'parse'
//import styled from 'styled-components'
//import Button from '../../style/elements/Button'
//import Box from '../../layout/Box'

//import MainHeading from '../../style/text/MainHeading'
//import SubHeading from '../../style/text/SubHeading'
//import UIMessage from "../../style/text/UIMessage";
//import Input from "../../style/elements/Input";
//import Flex from "../../layout/Flex";
import queryString from 'qs'
import {type, color} from '../../../designSystem'
import * as _ from "lodash";
import * as numeral from "numeral";
import NumberFormat from 'react-number-format';
import states from '../../data/states';
import styled from "@emotion/styled";
import CheckoutForm2 from './CheckoutForm2';
import {loadStripe} from '@stripe/stripe-js';
import Select from "givapp-design/src/components/Select";
import {
  Elements,
  ElementsConsumer
} from "@stripe/react-stripe-js";

//import {rem} from "../../../lib/tools";
//import Checkbox from "../../style/elements/Checkbox";
import {Link} from "react-router-dom";
//import Select from "../../style/elements/Select";
import shortid from "shortid";
//import Text from "../../style/text/Text";
import {login} from "../../../lib/auth";
import {Box, Button, Flex, Input, Text, theme, Toggle, UIMessage} from "givapp-design";
import {rem} from "givapp-design/src/lib/tools";
import * as breakpoints from "givapp-design/src/tokens/breakpoints";
import * as border from "givapp-design/src/tokens/border";
import ButtonSpecial from "givapp-design/src/special/online-giving/components/ButtonSpecial";
import Tooltip from "givapp-design/src/components/Tooltip";
import selectRecurringDayOptions from "givapp-design/src/special/online-giving/lib/selectRecurringDayOptions";
import * as space from "givapp-design/src/tokens/space";
import * as typography from "givapp-design/src/tokens/typography";
import imageDeviceiOS from "givapp-design/src/special/online-giving/images/device-store-button-ios.jpg";
import imageDeviceAndroid from "givapp-design/src/special/online-giving/images/device-store-button-android.jpg";
import addOrdinalSuffix from "givapp-design/src/special/online-giving/lib/addOrdinalSuffix";
import {StyledInput} from "givapp-design/src/components/Input/Input.component";
import Loading from "givapp-design/src/components/Loading";


const AppleStoreLink = styled.a`

`

const AppleStoreImage = styled.img`
  width: 175px;
`

const PlayStoreLink = styled.a`

`

const PlayStoreImage = styled.img`
  width: 200px;
`

const NumberFormatInput = StyledInput.withComponent(NumberFormat);


const OrgCard = styled(Box)`
  @media (min-width: ${rem(breakpoints.breakpointSm)}) {
    border-width: ${border.borderWidth};
    border-style: ${border.borderStyleDefault};
    border-color: ${props => props.theme.color.gray2};
    background-color: ${props => props.theme.color.white};
    border-radius: ${border.borderRadiusSm};
    box-shadow: 0 0 ${rem(2)} 0 ${props => props.theme.color.gray2Transparent};
  }
`


const StyledBox = styled(Box)`
  border-top-width: ${border.borderWidth};
  border-top-style: ${border.borderStyleDefault};
  border-top-color: ${({theme}) => theme.color.gray2};
`

const MainLogo = styled.img`
  display: block;
  max-width: ${rem(300)};
  width: 100%;
  flex-shrink: 0;

  @media (min-width: ${rem(breakpoints.breakpointSm)}) {
    max-width: ${rem(300)};
    width: 100%;
  }
`
const RecurringDayOption = styled.span`
  display: flex;
  width: ${space.s7};
  height: ${space.s6};
  align-items: center;
  justify-content: center;
  font-family: ${typography.fontFamily};
  font-size: ${typography.fontSizeMeta};
  font-style: normal;
  font-weight: ${props => props.selected ? typography.fontWeightBold : typography.fontWeightMedium};
  line-height: ${typography.lineHeightMultiplierMeta};
  color: ${props => props.selected ? props.theme.color.white : props.theme.color.black2};
  cursor: pointer;
  background-color: ${props => props.selected ? props.theme.color.classicBlue3 : 'transparent'};
  border-radius: ${border.borderRadiusMd};

  &:hover, &:focus, &:active {
    background-color: ${props => props.selected ? props.theme.color.classicBlue3 : props.theme.color.gray1};
  }
`


const NewRecurringDayRow = styled.span`
  display: block;
  width: 100%;
  height: 1px;
`


const StartGiv = styled(Box)`
  /* Stripe Input Helper */

  .StripeElement {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: ${space.s8};
    padding-top: ${space.s3};
    padding-right: 0;
    padding-bottom: 0;
    padding-left: ${space.s4};
    border-width: ${border.borderWidth};
    border-style: ${border.borderStyleDefault};
    border-color: ${({theme}) => theme.color.gray3};
    border-radius: ${border.borderRadiusMd};
  }
`

const InputWrapper = styled(Box)`
  @media (min-width: ${rem(breakpoints.breakpointSm)}) {
    max-width: calc(100% - 5rem);
  }
`

const createElementOptions = () => {
  return {
    style: {
      base: {
        height: rem(46),
        color: color.black,
        letterSpacing: '0.025em',
        fontFamily: type.family.default,
        '::placeholder': {
          'font-weight': type.weight.regular,
          'color': color.lightGray
        }
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};


export class PaymentDetails2 extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      error: null,
      organizationId: null,
      subaccountId: '',
      organization: null,
      subaccount: null,
      complete: false,
      submitted: false,
      config: null,
      mounted: false,

      showStartGiv: true,
      showLoginForm: false,
      showSignupForm: false,
      showPassword: false,
      showForgotPasswordLink: false,
      showCreateAccount: false,
      showAddress: false,
      showCreditCardForm: false,
      showPaymentOptions: false,
      showPaymentComplete: false,

      tooltipAddress: false,

      anonymous: false,
      amount: 0.0,


      username: '',
      firstName: '',
      lastName: '',
      phone: '',
      address1: '',
      city: '',
      state: false,
      zip: '',
      email: '',

      password: '',
      saving: false,

      recurringGivDays: []
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
    this.onPaymentMethodCreation = this.onPaymentMethodCreation.bind(this);
  }

  handleChange = (key, e) => (
    e => this.setState({[key]: e.target.value}, () => {
      //this.evaluateFlow(key);
    })

  )

  handlePassiveChange = key => {
    return e => this.state[key] = e.target.value;
  }

  handleSelectChange = (key, e) => (
    item => {
      console.log(item);
      this.setState({[key]: item.value}, () => {
          console.log(key, item);
          console.log(this.state.childOrganizations);
          if (key === 'subaccountId') {
            let subaccount = _.find(this.state.childOrganizations, (row) => {
              return row.id === this.state[key];
            });
            console.log('subaccount', subaccount);
            this.setState({'subaccount': subaccount})
          }
        }
      );
    }
  )


  handleToggleChange = (key) => (event) => {
    console.log('Handle toggle', key, event);
    this.setState({
      [key]: !this.state[key]
    });

  }

  handleInputChange = (key) => (event) => {
    console.log('Handle input', key, event, event.target.value);
    this.setState({
      [key]: parseInt(event.target.value)
    });

  }

  handleCurrencyInputChange = (key) => (event) => {
    console.log('Handle input', key, event, event.target.value, numeral(event.target.value).value());
    this.setState({
      [key]: parseInt(numeral(event.target.value).value())
    });

  }

  handleDoLogin = (event) => {
    event.preventDefault();
    this.setState({
      'showLoginForm': true,
      'showSignupForm': false,
      'error': null
    });
  }

  handleDoSignup = (event) => {
    event.preventDefault();
    this.setState({
      'showLoginForm': false,
      'showSignupForm': true,
      'error': null
    });
  }

  evaluateFlow = () => {

    debugger;
    if (!this.state.email) {
      return false;
    }

    Parse.Cloud.run('findDonorByEmail', {inputValue: this.state.email}).then((result) => {
      debugger;
      console.log('User found', result.user);
      console.log('Account found', result.account);
      result.user.set('isAnonymous', false);
      this.setState({
        showPassword: true,
        showForgotPasswordLink: true,
        showPaymentOptions: true,

        showStartGiv: false,
        showSignupForm: false,
        showCreateAccount: false,
        showCreditCardForm: typeof result.account === 'undefined',

        user: result.user,
        account: result.account
      });

      //if recurring
      //create account is selected but disabled (default to true)
      //else
      //create account is optional, enabled
    }, (result) => {
      console.log('No user found', result);
      //email doesn't exist
      this.setState({
        showPassword: false,
        showForgotPasswordLink: false,
        showStartGiv: false,

        showSignupForm: true,
        showPaymentOptions: true,
        showCreateAccount: false,
        createAccount: true,
        showCreditCardForm: true,
        user: this.props.user
      });
    });
  }

  async componentDidMount() {
    console.log('componentDidMount')

    Parse.Cloud.run('config').then((result) => {
      this.setState({
        config: result,
        stripePromise: loadStripe(result['STRIPE_PUBLIC_KEY'])
      });

    });
    debugger;
    if (this.props.user && this.props.user.get('isAnonymous')) {
      console.log("There is user that is anonymous.")
      this.setState({
        user: this.props.user,
        showUserOptions: true
      }, () => {
        this.loadOrganizationsFromASource();
      });
    } else if (this.props.user) {
      console.log("There is a user that is not anonymous.")
      this.setState({
        user: this.props.user,
        showUserOptions: false,
        showStartGiv: false,
        showPaymentOptions: true
      }, async () => {
        this.loadOrganizationsFromASource();

        debugger;
        let email = this.props.user.get('email');
        if (typeof email === 'undefined') {
          email = this.props.user.get('username');
        }
        let result = await Parse.Cloud.run('findDonorByEmail', {inputValue: email});
        if (result.account) {
          this.setState({
            account: result.account
          });
        } else {
          //stripePromise = loadStripe(result['STRIPE_PUBLIC_KEY']);
          this.setState({
            showCreditCardForm: true
          });
        }
      });

    } else {
      console.log("There is no user.")
    }
  }

  loadOrganizationsFromASource() {
    if (!!this.props.location.state) {
      console.log('componentWillMount:loadingFromState');

      const organizationId = this.props.location.state.organization || ''
      const subaccountId = this.props.location.state.subaccount || ''
      //const amount = this.props.location.state.amount || 21.0

      //debugger;

      this.setState({
        'organizationId': organizationId,
        'subaccountId': subaccountId,
        'mounted': true
      }, () => {
        this.loadOrganizations();
      });
    } else if (this.props.location.search) {
      debugger;
      console.log('componentWillMount:loadingFromSearch');
      console.log('componentWillMount:loadingFromSearch', this.state.user);

      const values = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true});
      console.log('componentWillMount:loadingFromSearch', values);
      const {organizationId} = values;

      const amount = values.amount ? Number.parseInt(values.amount) : this.state.amount;


      Parse.Cloud.run('fetchOrganization', {organization: organizationId}).then((organization) => {
        let parentOrganization = organization.get('parentOrganization');
        if (parentOrganization) {
          this.setState({
            'organization': parentOrganization,
            'subaccount': organization,
            'organizationId': parentOrganization.id,
            'subaccountId': organization.id,
            'mounted': true,
            'amount': amount
          }, () => {
            Parse.Cloud.run('fetchAllChildOrganizations', {organization: parentOrganization.id}).then((subaccounts) => {
              this.setState({childOrganizations: subaccounts.filter((subaccount) => subaccount.get('live'))});
            });
          });

        } else {
          this.setState({
            'organization': organization,
            'organizationId': organizationId,
            'mounted': true,
            'amount': amount
          }, () => {
            Parse.Cloud.run('fetchAllChildOrganizations', {organization: organizationId}).then((subaccounts) => {
              this.setState({childOrganizations: subaccounts.filter((subaccount) => subaccount.get('live'))});
            });
          });

        }
      });
    }
  }

  loadOrganizations() {
    if (this.state.organizationId && this.state.mounted) {
      console.log('componentDidMount: loading Orgs')
      Parse.Cloud.run('fetchOrganization', {organization: this.state.organizationId}).then((result) => {
        this.setState({organization: result});
        if (this.state.subaccountId) {
          Parse.Cloud.run('fetchOrganization', {organization: this.state.subaccountId}).then((result) => {
            this.setState({subaccount: result});
          });
        }
      });

      Parse.Cloud.run('fetchAllChildOrganizations', {organization: this.state.organizationId}).then((subaccounts) => {
        this.setState({
          childOrganizations: subaccounts.filter((subaccount) => {
            subaccount.get('live')
          })
        });
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onError(error) {
    this.setState({error: error, saving: false});
  }

  async doLogin() {
    const {email, password} = this.state;
    return new Promise((resolve, reject) => {
      login(
        email,
        password
      ).then((loginResult) => {
        this.setState({
          user: loginResult,
          showPassword: false,
          showUserOptions: false,
          saving: true
        });
        resolve(loginResult);
      }, (error) => {
        this.setState({
          error: error,
          saving: false
        });
        reject(error);
      })
    });
  }

  async onPaymentMethodCreation(paymentMethod) {

    debugger;
    this.setState({'submitted': true});

    if (this.state.showPassword) {
      await this.doLogin();
    }

    Parse.Cloud.run('submitAnonymousPayment', {
      paymentMethod: paymentMethod,
      organizationId: this.state.subaccountId ? this.state.subaccountId : this.state.organizationId,
      amount: this.state.amount,
      coverTransactionFees: this.state.coverTransactionFees,
      email: this.state.email,
      recurringPayment: this.state.recurringPayment,
      recurringDaysOfMonth: this.state.recurringGivDays,
      donationOrigin: 'WEB'
    }).then((result) => {
      Parse.User.logOut();
      //this.props.history.replace('/donate/payment-details', {});
      this.setState({showPaymentComplete: true, saving: false});
    }, (error) => {
      if (error.message) {
        this.setState({error: {message: error.message}, saving: false});
      } else {
        this.setState({error: {message: error}, saving: false});
      }

    });


    //create new Donation
    //save Donation for user to org/subaccount
  }

  async startGiving(e) {
    this.evaluateFlow();
  }

  async submitKnownUserGiv(e) {
    console.log('PaymentDetails:submitKnownUserGiv');

    if (this.state.saving) {
      return;
    } else {
      const {
        showPassword,
        user,
        email,
        password
      } = this.state


      this.setState({saving: true}, () => {
        let promise = new Promise((resolve, reject) => {

          if (showPassword) {

            console.log('PaymentDetails:submitKnownUserGiv.Promise.showLoginForm');
            login(
              email,
              password
            ).then((loginResult) => {
              this.setState({
                user: loginResult,
                showPassword: false,
                showUserOptions: false,
                saving: true
              });
              resolve(user);
            }, (error) => {
              this.setState({
                error: error,
                saving: false
              });
              reject(error);
            })
          } else {
            resolve();
          }
        });

        return promise.then((user) => {

          debugger;
          //does the user have an account?  No?  Create one.

          Parse.Cloud.run('submitAnonymousPayment', {
            organizationId: this.state.subaccountId ? this.state.subaccountId : this.state.organizationId,
            amount: this.state.amount,
            coverTransactionFees: this.state.coverTransactionFees,
            recurringPayment: this.state.recurringPayment,
            recurringDaysOfMonth: this.state.recurringGivDays,
            email: this.state.email,
            donationOrigin: 'WEB'
          }).then((result) => {
            Parse.User.logOut();
            //this.props.history.replace('/donate/payment-details', {});
            this.setState({showPaymentComplete: true, saving: false});
          }, (error) => {
            if (error.message) {
              this.setState({error: {message: error.message}, saving: false});
            } else {
              this.setState({error: {message: error}, saving: false});
            }
          });
        }, (error) => {
          console.log('user login was NOT successful');
          this.setState({
            'error': error,
            'saving': false
          });
          return Promise.reject(error);
        });
      });
    }
  }

  async onSubmit(state) {
    console.log('PaymentDetails:onSubmit')

    let promise = new Promise((resolve, reject) => {
      this.setState({
        error: null,
        saving: true
      }, () => {

        const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordRE = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

        const {
          showSignupForm,
          showPassword,
          showAddress,
          showCreateAccount,
          user,
          firstName,
          lastName,
          phone,
          address1,
          city,
          state,
          zip,
          email,
          password,
          createAccount
        } = this.state

        if (user.get("isAnonymous") === false) {
          //we're good
        } else if (user.get("isAnonymous") === true && showPassword) {
          //check u/p
          if (email === null || email === '') {
            reject({message: 'Email is required.'});
            return;
          } else if (password === null || password === '') {
            /* (?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,} */
            reject({message: 'Password is required.'});
            return;
          }
        } else if (showSignupForm) {
          if (email === null || email === '') {
            reject({message: 'Email is required.'});
            return;
          } else if (!emailRE.test(email)) {
            reject({message: 'Email is invalid'});
            return;
          }
          if (showAddress) {
            if (phone === null || phone === '') {
              reject({message: 'Phone is required.'});
              return;
            } else if (address1 === null || address1 === '') {
              reject({message: 'Address is required.'});
              return;
            } else if (city === null || city === '') {
              reject({message: 'City is required.'});
              return;
            } else if (state === null || state === '' || state === false) {
              reject({message: 'State is required.'});
              return;
            } else if (zip === null || zip === '') {
              reject({message: 'Zip code is required.'});
              return;
            }
          }
          if (createAccount) {
            if (firstName === null || firstName === '') {
              reject({message: 'First name is required.'});
              return;
            } else if (lastName === null || lastName === '') {
              reject({message: 'Last name is required.'});
              return;
            } else if (password === null || password === '') {
              reject({message: 'Password is required.'});
              return;
            } else if ((!passwordRE.test(password) && password.length <= 24)) {
              reject({message: 'Password must of length 6 to 24 and contain at least 1 uppercase character, at least 1 lower case character, at least 1 number.'});
              return;
            }
          }
        }

        console.log('PaymentDetails:onSubmit.Promise', this.state);

        if (showSignupForm && createAccount) {
          console.log('PaymentDetails:onSubmit.Promise.showSignupForm');
          //create a new account

          debugger;
          Parse.User.signUp(email,
            password, {
              fullName: firstName + ' ' + lastName,
              firstName: firstName,
              lastName: lastName,
              email: email,
              isAdmin: false,
              welcomeMessageShown: false
            }, {}).then((result) => {
            this.setState({
              user: result,
              showSignupForm: false,
              showUserOptions: false,
              saving: true,
              showPassword: false,
              showCreateAccount: false,
              createAccount: false
            });
            resolve(result);
          }, (error) => {
            this.setState({
              error: error,
              saving: false
            });
            reject(error);
          });
        } else if (user.get("isAnonymous") === true && showPassword) {
          //login to an existing account

          console.log('PaymentDetails:onSubmit.Promise.showLoginForm');
          login(
            email,
            password
          ).then((loginResult) => {
            this.setState({
              user: loginResult,
              showPassword: false,
              showUserOptions: false,
              saving: true
            });
            resolve(user);
          }, (error) => {
            this.setState({
              error: error,
              saving: false
            });
            reject(error);
          })

        } else if (user && !createAccount) {
          console.log('PaymentDetails:onSubmit.loggedIn');
          resolve(user);
        } else {
          console.log('PaymentDetails:onSubmit.reject');
          reject({
            message: "No valid user to create donation"
          });

        }

      });


    });
    return promise.then((result) => {
      console.log('user process was successful');
      return result;
    }, (error) => {
      console.log('user process was NOT successful');
      this.setState({
        'error': error,
        'saving': false
      });
      return Promise.reject(error);
    });
  }

  incrementDonation = (e, type) => {
    const currentAmount = this.state.amount ? this.state.amount : 1

    // no negatives
    if ((currentAmount - 1) < 0) return

    // +1 / -1
    if (type === 'increase') {
      this.setState({amount: currentAmount + 1})
    }
    if (type === 'decrease') {
      this.setState({amount: currentAmount - 1})
    }

  }
  getDataDay = (e) => (
    !!e &&
    !!e.target.attributes.hasOwnProperty('data-day') &&
    e.target.attributes['data-day'].value * 1
  )

  processRecurringGivDay = (e) => {
    e.preventDefault()
    console.log('processRecurringGivDay', e);

    const dataDay = this.getDataDay(e)
    const isActive = this.state.recurringGivDays.includes(dataDay)

    if (!isActive) {
      // new day, add to state and update
      return this.setState({
        recurringGivDays: [...this.state.recurringGivDays, dataDay]
      });
    } else {
      // previously selected day, remove and update state
      const updatedRecurringGivDays = this.state.recurringGivDays.filter(givDay => !(givDay === dataDay))

      // always have to have one day selected, if empty, reset to 1
      if (updatedRecurringGivDays.length < 1) updatedRecurringGivDays.push(1)

      this.setState({recurringGivDays: updatedRecurringGivDays});
    }
  }

  renderDay = (day) => {
    const isSelected = !!(this.state.recurringGivDays.includes(day.value))

    /*
      Add an empty full space span every seven items
      to force a new row... kind of hacky...
    */
    const newRow = !!(day.value % 7 === 0)

    return newRow ?
      <React.Fragment key={day.value}>
        <RecurringDayOption selected={isSelected} onClick={(e) => this.processRecurringGivDay(e)} data-day={day.value}>
          {day.label}
        </RecurringDayOption>
        <NewRecurringDayRow/>
      </React.Fragment> :
      <RecurringDayOption key={day.value} selected={isSelected} onClick={(e) => this.processRecurringGivDay(e)}
                          data-day={day.value}>
        {day.label}
      </RecurringDayOption>
  }

  render() {

    let {selectedTheme} = this.props;
    console.log("PaymentDetails2", selectedTheme);
    const imgStyle = {
      width: '200px'
    };
    let {
      error,
      organization,
      subaccount,
      subaccountId,
      config,
      mounted,
      childOrganizations,

      showStartGiv,
      showPassword,
      showSignupForm,
      showForgotPasswordLink,
      showCreateAccount,
      showAddress,
      showPaymentOptions,
      showCreditCardForm,
      showPaymentComplete,
      tooltipAddress,

      user,
      account,
      anonymous,
      createAccount,

      amount,
      coverTransactionFees,
      recurringPayment,
      recurringGivDays,
      stripePromise,
      saving
    } = this.state

    console.log("render:error", error);

    var x = function (e, t, r) {
      var o = (e + r) / ((100 - t) / 100);
      return {fee: o, total: o - e}
    }
    var stripeProcessingFee = 0.029;
    var stripeProcessingMinFee = 0.3;
    const stripeFee = (amount) => {
      if (amount <= 0) return 0;
      const amountTax = amount / 100 * stripeProcessingFee;
      const minFeeTax = stripeProcessingMinFee / 100 * stripeProcessingFee;
      const tax = amountTax
        + (amountTax + minFeeTax) / 100 * stripeProcessingFee
        + minFeeTax
        + stripeProcessingMinFee;
      return Math.ceil(amount + tax);
    };
    var amountWithFees = amount;
    if (coverTransactionFees && amount > 0) {
      let baseAmount = amount;

      // our original amount
      console.log('baseAmount', baseAmount);

      // add 0.30 fee
      let netWithFixedFee = baseAmount + 0.3;
      console.log('netWithFixedFee', netWithFixedFee);

      // round, just in case, no effect really
      let roundedAmount = Math.floor(100 * netWithFixedFee) / 100;
      console.log('roundedAmount', roundedAmount);

      // calc based on percentage of (0.29)
      let finalAmount = Math.floor(100 * roundedAmount / 0.971) / 100;
      console.log('finalAmount', finalAmount);

      // format amount with fees as a string
      amountWithFees = numeral(finalAmount).format("0.00", Math.floor);
      console.log('amountWithFees', amountWithFees);

      // calculate actual fees based on math above
      let actualFees = Math.floor(100 * (finalAmount * .029 + .30)) / 100;
      console.log('actualFees', actualFees);

      // calculate resulting net amount of donation
      console.log('netAfterFees', finalAmount - actualFees)

      // confirm no disparities (100x is javascript tomfoolery)
      console.log('0 if math is correct', finalAmount * 100 - baseAmount * 100 - actualFees * 100)
    }

    if (createAccount && !user) {
      showPassword = true;
    }

    const childOrganizationChoices = _.map(childOrganizations, (row) => {
      return {
        label: row.get('name'),
        value: row.id
      }
    });

    const stateChoices = _.chain(states).toPairs().map((row) => {
      return {
        label: row[1],
        value: row[0]
      }
    }).value();

    console.log('PaymentDetails2:stateChoices', stateChoices);

    const donationAmount = (coverTransactionFees && amount > 0) ? amountWithFees : amount;

    if (showPaymentComplete) {
      return (
        <OrgCard
          width={'90%'}
          maxWidth={rem(1080)}
          mt={5}
          mb={6}
          mx={'auto'}
          pb={[9, rem(72), rem(100)]}
          px={[3, 5]}
          background={selectedTheme.color.white}
          theme={selectedTheme}
        >
          <Flex flexDirection={'column'}>
            <Flex width={1 / 1} pt={5} justifyContent={'center'} alignItems={'flex-start'}>
              <MainLogo src={organization.get('logo')['_url']} alt={organization.get('name')}/>
            </Flex>
            <Flex width={1 / 1} mt={4} maxWidth={rem(800)} mx={'auto'} flexDirection={'column'}
                  justifyContent={'center'}>
              <Flex width={1 / 1} mt={4} maxWidth={rem(800)} mx={'auto'} flexDirection={'column'}
                    justifyContent={'center'}>
                {subaccount ?
                  <React.Fragment>
                    <Text px={[0, 9]} as="h1" center marketingTitle lineHeight={typography.lineHeightMultiplierMeta}>Thank
                      you for your {!!recurringPayment && 'recurring '}donation to {subaccount.get('name')}!</Text>
                    <Text as="h3" mt={5} maxWidth={'100px'} center title1 berry>{organization.get('name')}</Text>
                  </React.Fragment> :
                  <Text px={[0, 9]} as="h1" center marketingTitle lineHeight={typography.lineHeightMultiplierMeta}>Thank
                    you for your {!!recurringPayment && 'recurring '}donation to {organization.get('name')}!</Text>
                }

                <Box maxWidth={rem(640)} mx={'auto'} pb={5}>
                  <Text as="p" large mt={[8, 9]}>Your total {!!recurringPayment && 'monthly '}donation amount is
                    <Text as="strong" large black bold> ${donationAmount}</Text>.
                  </Text>

                  {/* FIXME: Not an actual fee calculation!!! Will need a real one */}
                  {!!recurringPayment &&
                    <Text as="p" large mt={3}>
                      Your monthly donation in the amount of ${donationAmount} will
                      come out on the {recurringGivDays.length === 1 ?
                      `${addOrdinalSuffix(recurringGivDays[0])} of the month` :
                      `${recurringGivDays.map((givDay, i) => `${addOrdinalSuffix(givDay)}${(i < recurringGivDays.length - 2) ? ', ' : (i === recurringGivDays.length - 1) ? '' : ' and '} `).join('')} of the month`
                    }.
                    </Text>
                  }

                  <Text as="p" large mt={3}>
                    An email receipt of this donation has been sent to <Text as="strong" large black
                                                                             bold>{user.get('email') ? user.get('email') : this.state.email}</Text>.
                  </Text>
                </Box>

                <StyledBox mt={[5, 9]} pt={[6, 9]} width={1 / 1}>
                  <Text as="h3" title1 textColor={selectedTheme.color.positive3} center>Check out GivApp’s mobile
                    app!</Text>
                  <Text mt={[3, 2]} large gray center>Available on the App Store and Google Play.</Text>
                </StyledBox>
                <Flex
                  mt={5}
                  justifyContent={'space-between'}
                  alignItems={['center', 'flex-start']}
                  flexDirection={['column', 'row']}
                >
                  <Box mr={[0, 2]} ml={[0, 'auto']}>
                    <a href="https://apps.apple.com/us/app/id1436055719" title="View GivApp on the App Store">
                      <img src={imageDeviceiOS} alt="GivApp is available on the App Store." width="189"/>
                    </a>
                  </Box>
                  <Box mt={[8, 0]} ml={[0, 2]} mr={[0, 'auto']}>
                    <a href="https://play.google.com/store/apps/details?id=com.givapp.givapp.givapp"
                       title="View GivApp on Google Play">
                      <img src={imageDeviceAndroid} alt="Get GivApp on Google Play." width="181"/>
                    </a>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </OrgCard>)

    } else if (mounted && organization && config) {
      return (
        <OrgCard
          width={'90%'}
          maxWidth={rem(1080)}
          mt={5}
          mb={6}
          mx={'auto'}
          pb={[9, rem(72), rem(100)]}
          px={[3, 5]}
          background={selectedTheme.color.white}
          theme={selectedTheme}
        >
          <Flex flexDirection={'column'}>
            <Flex width={1 / 1} pt={5} justifyContent={'center'} alignItems={'flex-start'}>

              {!!organization && !subaccount && organization.get('logo') &&
                <MainLogo src={organization.get('logo')['_url']} alt={organization.get('name')}/>
              }
              {!!subaccount && subaccount.get('logo') &&
                <MainLogo src={subaccount.get('logo')['_url']} alt={subaccount.get('name')}/>
              }
            </Flex>

            <Flex width={1 / 1} mt={4} flexDirection={'column'} justifyContent={'center'}>
              {subaccount ?
                <React.Fragment>
                  <Text px={[0, 9]} as="h1" center marketingTitle>Give to {subaccount.get('name')}!</Text>
                  <Text as="h3" mt={3} maxWidth={'100px'} center title1 berry>{organization.get('name')}</Text>
                </React.Fragment> :
                <Text px={[0, 9]} as="h1" center marketingTitle>Give to {organization.get('name')}!</Text>
              }
            </Flex>

            <Flex alignItems={'center'} mb={6}>
              <Box mt={1} ml={'auto'}>
                <ButtonSpecial type="decrease" isDisabled={!!(amount <= 0)}
                               onClick={(e) => this.incrementDonation(e, 'decrease')}/>
              </Box>
              <InputWrapper>
                <NumberFormatInput thousandSeparator={true} prefix={'$'}
                                   value={amount.toString()}
                                   decimalScale={0}
                                   placeholder="0"
                                   fancy={true}
                                   large
                                   bold
                                   autoFocus

                                   onChange={(e) => this.handleCurrencyInputChange('amount')(e)}/>
                {/*<Input
                  fancy
                  large
                  bold
                  autoFocus
                  mt={1}
                  type="number"
                  min="0"
                  step="1"
                  value={amount}
                  defaultValue={amount}

                  placeholder="0"
                />*/}
              </InputWrapper>

              <Box mt={1} mr={'auto'}>
                <ButtonSpecial onClick={(e) => this.incrementDonation(e, 'increase')}/>
              </Box>
            </Flex>

            {!!childOrganizations && childOrganizations.length > 0 &&
              <Box mt={rem(2)}>
                <Text mb={1} block as="label" meta gray>Optional line item</Text>
                <Select
                  large
                  clearable
                  placeholder="Line item (optional)"
                  options={childOrganizationChoices}
                  onChange={(option) => this.handleSelectChange('subaccountId')(option)}
                />
              </Box>}

            {!!user && user.get('isAnonymous') &&

              <Input
                mt={1}
                large
                required
                id="email"
                name="email"
                type="email"
                placeholder="Email address"
                value={this.state.email}
                onChange={this.handleChange('email')}
              />
            }


            {!!showStartGiv &&
              <Box mt={2}>
                <Button full large mt={2} onClick={(e) => this.startGiving(e)}>Start Giv</Button>
              </Box>
            }

            {!!user && !user.get('isAnonymous') &&
              <Box mt={rem(2)} mb={rem(2)}>
                <Text>Welcome back {!user.get('fullName') ? user.get('fullName') : ''}</Text>
                {!!account &&
                  <Box>
                    {!!account && !account.get('accountType') &&
                      <Text>There is an account on file.</Text>
                    }
                    {!!account && account.get('accountType') &&
                      <Text>There is a {account.get('accountType')} account on file
                        with the last 4 numbers {account.get('card_last4')}
                        expiring {account.get('card_expiration_month')}/{account.get('card_expiration_year')}.</Text>
                    }
                  </Box>
                }
              </Box>
            }

            {!!showSignupForm &&
              <Flex flexDirection={['column']}>

                <Box mt={rem(2)}>
                  <Toggle
                    label="Add Contact Information"
                    isSwitchActive={showAddress}
                    onClick={(e) => this.handleToggleChange('showAddress')(e)}
                  />
                </Box>
                {!!showAddress &&
                  <Flex mt={1} flexDirection={['column']}>
                    <Box mt={rem(2)}>
                      <Input
                        required
                        large
                        id="phone"
                        name="phone"
                        type="phone"
                        placeholder="Phone"
                        value={this.state.phone}
                        disabled={anonymous ? 'disabled' : false}
                        onChange={this.handleChange('phone')}
                      />
                    </Box>
                    <Box mt={rem(2)}>
                      <Input
                        required
                        large
                        id="address1"
                        name="address1"
                        type="address1"
                        placeholder="100 Giv St."
                        value={this.state.address1}
                        disabled={anonymous ? 'disabled' : false}
                        onChange={this.handleChange('address1')}
                      />
                    </Box>
                    <Box mt={rem(2)}>
                      <Input
                        required
                        large
                        id="city"
                        name="city"
                        type="city"
                        placeholder="Giville"
                        value={this.state.city}
                        disabled={anonymous ? 'disabled' : false}
                        onChange={this.handleChange('city')}
                      />
                    </Box>
                    <Flex mt={1} flexDirection={['column', 'row']}>
                      <Box width={[1 / 1, 3 / 5]} mr={[0, 1]}>
                        <Select
                          large
                          placeholder="State"
                          options={stateChoices}
                          onChange={(option) => this.handleSelectChange('state')(option)}
                        />
                      </Box>
                      <Box width={[1 / 1, 2 / 5]} mt={[1, 0]}>
                        <Input
                          required
                          large
                          id="zip"
                          name="zip"
                          type="zip"
                          placeholder="ZIP code"
                          value={this.state.zip}
                          disabled={anonymous ? 'disabled' : false}
                          onChange={this.handleChange('zip')}
                        />
                      </Box>
                    </Flex>
                    <Flex alignItems={'center'}>
                      <Text mt={1} meta gray>This doesn’t have to match the billing address of your card.</Text>
                      <Box mt={1} ml={rem(2)}>
                        <Tooltip
                          isTooltipActive={tooltipAddress}
                          iconColor={selectedTheme.color.lightGray4}
                          iconSize={18}
                          onClick={(e) => this.handleToggleChange('tooltipAddress')(e)}
                          content="We use the ZIP code entered in the Payment Card field for processing the donation."
                        />
                      </Box>
                    </Flex>
                  </Flex>
                }
              </Flex>
            }
            {!!showCreateAccount &&
              <Box mt={rem(4)}>
                <Toggle
                  label="Create Account"
                  isSwitchActive={createAccount}
                  onClick={(e) => this.handleToggleChange('createAccount')(e)}
                />
              </Box>
            }
            {!!createAccount &&
              <Box mt={4}>
                <Text mb={1} block as="label" meta gray>Create account</Text>
              </Box>
            }
            {!!createAccount &&
              <Flex mt={1} flexDirection={['column', 'row']}>
                <Input
                  required
                  large
                  mr={[0, 1]}
                  id="firstName"
                  name="firstName"
                  type="firstName"
                  placeholder="First Name"
                  value={this.state.firstName}
                  disabled={anonymous ? 'disabled' : false}
                  onChange={this.handleChange('firstName')}
                />
                <Input
                  required
                  large
                  mt={[1, 0]}
                  id="lastName"
                  name="lastName"
                  type="lastName"
                  placeholder="Last Name"
                  value={this.state.lastName}
                  disabled={anonymous ? 'disabled' : false}
                  onChange={this.handleChange('lastName')}
                />
              </Flex>
            }
            {!!(showPassword || createAccount) &&
              <Box>
                <Box mt={rem(2)}>
                  <Input
                    required
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    disabled={anonymous ? 'disabled' : false}
                    onChange={this.handlePassiveChange('password')}
                  />
                </Box>
              </Box>
            }

            {!!showForgotPasswordLink &&
              <Box mt={1}>
                <Text minor>
                  <Link to="/forgot-password">Forgot password?</Link>
                </Text>
              </Box>
            }

            {!!showPaymentOptions &&
              <Flex flexDirection={['column']}>
                <Box mt={1}>
                  <Toggle
                    label="Cover Transaction Fees"
                    isSwitchActive={coverTransactionFees}
                    onClick={(e) => this.handleToggleChange('coverTransactionFees')(e)}
                  />
                </Box>


                {(user.get("isAnonymous") === false || typeof user.get("isAnonymous") === 'undefined' || createAccount) &&
                  <Box mt={1}>
                    <Toggle
                      label="Make this donation recurring"
                      isSwitchActive={recurringPayment}
                      onClick={(e) => this.handleToggleChange('recurringPayment')(e)}
                    />
                    {!!recurringPayment &&
                      <Box mb={3}>
                        <Text ml={rem(56)} as="span" block meta gray>Select donation day(s) below</Text>

                        <Flex mt={2} ml={[0, rem(56)]} mr={[0, 9]} justifyContent="space-between" flexWrap="wrap">
                          {selectRecurringDayOptions.map(day => this.renderDay(day))}
                        </Flex>
                      </Box>
                    }
                  </Box>
                }
              </Flex>

            }


            {!!showCreditCardForm &&
              <StartGiv mt={3}>
                <Elements
                  stripe={stripePromise}
                  {...createElementOptions()}
                  fonts={[
                    {'cssSrc': 'https://use.typekit.net/bln6vbi.css'}
                  ]}>
                  <InjectedCheckoutForm
                    amount={amount}
                    coverTransactionFees={coverTransactionFees}
                    saving={saving}
                    error={error}
                    onSubmit={this.onSubmit}
                    onError={this.onError}
                    onPaymentMethodCreation={this.onPaymentMethodCreation}
                    theme={selectedTheme}></InjectedCheckoutForm>

                </Elements>
              </StartGiv>
            }
            {!!!showCreditCardForm && showPaymentOptions &&
              <Flex mt={2} flexDirection={['column']} justifyContent="space-between" alignItems={'center'}>
                {!!error &&
                  <Box mt={3} width={[1]}>
                    <UIMessage type="error">Error: {error.message}</UIMessage>
                  </Box>
                }
                <Box mt={rem(2)} width={[1]}>
                  <Button full large mt={5} onClick={(e) => this.submitKnownUserGiv(e)} disabled={this.state.saving}>
                    <Flex alignItems={'center'} justifyContent={'center'}>
                      {!!this.state.saving &&
                        <Loading dotColor={selectedTheme.color.classicBlue1}/>
                      }
                      {this.state.saving ? 'Please wait. Donating ' : 'Donate '}
                      to {subaccount ? subaccount.get('name') : organization.get('name')}
                    </Flex>
                  </Button>
                </Box>
              </Flex>
            }

            {!!coverTransactionFees && amount > 0 &&
              <Box mt={2} mb={rem(2)}>
                <Text>
                  Your total Giv with fees is ${amountWithFees}.
                </Text>
              </Box>
            }
            {!!coverTransactionFees === false &&
              <Box mt={2} mb={2}>
                <Text>
                  Your total Giv with fees is ${amount}.
                </Text>
              </Box>
            }
          </Flex>
        </OrgCard>
      )
    } else {
      return (
        <OrgCard
          width={'90%'}
          maxWidth={rem(1080)}
          mt={5}
          mb={6}
          mx={'auto'}
          pb={[9, rem(72), rem(100)]}
          px={[3, 5]}
          background={selectedTheme.color.white}
          theme={selectedTheme}
        >
          <Box>
            <Text title1>Loading...</Text>
          </Box>
        </OrgCard>
      )
    }

  }
}

export default PaymentDetails2


const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm2
        stripe={stripe}
        elements={elements}
        amount={props.amount}
        coverTransactionFees={props.coverTransactionFees}
        saving={props.saving}
        error={props.error}
        onSubmit={props.onSubmit}
        onError={props.onError}
        onPaymentMethodCreation={props.onPaymentMethodCreation}
        theme={props.theme}></CheckoutForm2>
    )}
  </ElementsConsumer>
);
