import React from 'react'
import Box from '../../layout/Box'

import MainHeading from '../../style/text/MainHeading'
import SubHeading from "../../style/text/SubHeading";
import {ManagerComponent} from "./ManagerComponent";

import * as _ from 'lodash';
import {addDonorNoteForOrganization, fetchDonor} from "./ManagerApi";
import Text from "givapp-design/src/components/Text";
import {Button, IconOneTime, IconRecurring, IconSpareChange, TableHead} from "givapp-design";
import {Link, Route, Switch} from "react-router-dom";
import {rem} from "givapp-design/src/lib/tools";
import Avatar from "givapp-design/src/components/Avatar";
import Flex from "givapp-design/src/components/Flex";
import Modal from "react-modal";
import {dateRangeOptions, donationTypeOptions} from "./Donations";
import styled from "@emotion/styled";
import * as space from "givapp-design/src/tokens/space";
import * as typography from "givapp-design/src/tokens/typography";
import * as border from "givapp-design/src/tokens/border";
import LoadingCard from "./components/LoadingCard";
import DonorRoutes from "../../routes/DonorRoutes";
import * as spaceTokens from "givapp-design/src/tokens/space";
import {order} from "styled-system";

const TableControls = styled(Flex)`
  background-color: #F3F5F5;
  border-top: 1px solid #DCE0E2;
`

const PageControls = styled(Flex)`
  border-top: 1px solid #DCE0E2;
  border-bottom: 1px solid #DCE0E2;
`

const StyledTextArea = styled.textarea`
  width: calc(100% - ${rem(28)});
  height: 100px;
  padding: ${props =>
          props.isSearch ? spaceTokens.s6 :
                  props.small ? spaceTokens.s2 :
                          props.large ? spaceTokens.s4 :
                                  spaceTokens.s3
  };

  ${order}
  ${space}

  border-width: ${border.borderWidth};
  border-style: ${border.borderStyleDefault};
  border-color: ${props =>
          props.disabled ? props.theme.color.gray1 :
                  props.error ? props.theme.color.negative3 :
                          props.theme.color.gray3
  };
  background-color: ${props =>
          props.disabled ? props.theme.color.gray1 :
                  props.theme.color.white
  };
  border-radius: ${props => props.small ? border.borderRadiusSm : border.borderRadiusMd};
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;

  &:active, &:focus {
    border-color: ${props =>
            props.disabled ? props.theme.color.gray1 :
                    props.error ? props.theme.color.negative4 :
                            props.theme.color.classicBlue3
    };
    background-color: ${props =>
            props.disabled ? props.theme.color.gray1 :
                    props.theme.color.white
    };

  }

  &::-webkit-placeholder,
  &::placeholder {
    font-family: ${typography.fontFamily};
    font-weight: ${typography.fontWeightRegular};
    color: ${props => props.error ? props.theme.color.negative3 : props.theme.color.gray5};
  }
`

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    margin: '0px 10px 0px 10px'
  }
};


export class Donor extends ManagerComponent {


  constructor() {

    super();
    this.state = {
      page: 0,
      size: 10,
      total: 0,
      donations: [],
      loading: true,
      error: {},
      onUserUpdatedListeners: []
    }

    this.saveNote = this.saveNote.bind(this)
    this.onUserUpdated = this.onUserUpdated.bind(this)
  }

  componentDidMount() {
    super.componentDidMount();
    console.log('Donor.componentDidMount', this.props.match.params.donor_id);
    // Set active page state
    const {setPageId} = this.props
    setPageId('donors');

    console.log('Donor.componentDidMount:loading', this.state.loading);
    this.setState(_.extend({
      loading: true,
      donorId: this.props.match.params.donor_id
    }, this.state), () => {
      this.refreshDonations();
    });
  }

  refreshDonations() {
    this.setState({
      loading: true
    }, () => {
      let promises = [];
      promises.push(fetchDonor(this.state.donorId));

      Promise.all(promises).then((results) => {
        let donor = results[0];
        //let donations = results[1];
        //this.state.donations = donations.data;
        //this.state.total = donations.total;
        this.state.donor = donor;
        this.state.loading = false;
        this.setState(this.state);
      });
    });
  }

  addNote(event) {
    event.preventDefault();
    this.setState({
      addNoteModal: true,
      modalIsOpen: true
    })
  }

  afterOpenModal() {

  }

  closeModal() {
    this.setState({
      addNoteModal: null,
      modalIsOpen: false
    })
  }


  saveNote(event) {

    console.log('saving note', event, this.state.note, addDonorNoteForOrganization);
    addDonorNoteForOrganization(this.state.organization.id, this.state.donorId, this.state.note, {}).then(value => {
      this.userUpdated();
      this.closeModal();
    })
  }

  handleNoteChange = (event) => {
    event.preventDefault();
    console.log(event.target.value)
    //this.setState({
    //  note: event.target.value
    //});
    this.state.note = event.target.value;
  }

  onUserUpdated(func) {
    this.state.onUserUpdatedListeners.push(func);
  }

  userUpdated() {
    console.log('Donor:userUpdated');
    _.each(this.state.onUserUpdatedListeners, (func) => {
      func.apply();
    })
  }

  render() {

    const {
      organization,
      subaccounts,
      total, donations, filter, donorId, donor, loading,
      modalIsOpen,
      error,
      addNoteModal,
      note
    } = this.state;

    console.log('Donor:render', loading);

    const {selectedTheme} = this.props;
    let customDateRange = this.state.customDateRange;
    if (!customDateRange) {
      customDateRange = [new Date(), new Date()]
    }


    const afterOpenModal = this.afterOpenModal;
    const closeModal = this.closeModal;

    const campaignOptions = _.map(subaccounts, (subaccount) => {
      return {
        label: subaccount.name,
        value: subaccount.id
      }
    });

    return (
      <>
        <Box m={4}>
          {!!loading == true &&
            <LoadingCard>Loading...</LoadingCard>
          }
          {!!donor &&
            <>
              <Flex justifyContent={'space-between'}>
                <Flex>
                  <Box mr={2}>
                    <Avatar url={donor.avatar} size={72}/>
                  </Box>
                  <Box>
                    <Text fontSize={26} black>{donor.get('firstName')} {donor.get('lastName')}</Text>
                    <Text fontSize={16} gray>{donor.get('username')}</Text>
                    <Text fontSize={16} gray>{donor.get('phone')}</Text>
                  </Box>

                </Flex>
                <Box>
                  <Text noUnderline>
                    <Button small onClick={(e) => this.addNote(e)}>Add Note</Button>
                  </Text>
                </Box>
              </Flex>
              <PageControls mt={2} pt={2} pb={2} justifyContent={'space-between'}>

                <Flex>
                  <Text noUnderline mr={2}>
                    <Link to={`/donors/${donor.id}/summary`}>Summary</Link>
                  </Text>
                  <Text noUnderline mr={2}>
                    <Link to={`/donors/${donor.id}/donations`}>Donations</Link>
                  </Text>
                  <Text noUnderline mr={2}>
                    <Link to={`/donors/${donor.id}/notes`}>Notes</Link>
                  </Text>
                </Flex>
                <Box>
                  <Text noUnderline>
                    <Link to={`/donors/${donor.id}/edit`}>Edit</Link>
                  </Text>
                </Box>

              </PageControls>

              <DonorRoutes
                {...this.props}
                organization={organization}
                donor={donor}
                setPageId={this.setPageId}
                selectedTheme={selectedTheme}
                onUserUpdated={this.onUserUpdated}
              />
              {/*
              <!--
              <AuthenticatedRoute
                exact
                user={this.props.user}
                path={`/donors/${donor.id}`}
                component={() => <Donations {...this.props}/>}
              />
              <AuthenticatedRoute
                exact
                user={this.props.user}
                path={`/donors/${donor.id}/donations`}
                component={() => <Donations {...this.props}/>}
              />-->
              */}
            </>
          }

        </Box>
        {!!addNoteModal &&
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={modalStyles}
            contentLabel="Add a Note"
          >
            <Flex mt={4} flexDirection={['column']}>
              <Box width={[1 / 1]}>
                <SubHeading>Note</SubHeading>
                <StyledTextArea
                  error={error.note}
                  value={note}
                  onChange={(e) => this.handleNoteChange(e)}
                  id='note'
                ></StyledTextArea>
              </Box>
              <Flex mt={4} flexDirection={['row']} justifyContent={'end'}>
                  <Button small onClick={(e) => this.saveNote(e)} mr={2}>Save</Button>
                  <Button small outline onClick={(e) => this.closeModal(e)}>Cancel</Button>
              </Flex>
            </Flex>
          </Modal>
        }
      </>

    )
  }
}

export default Donor
