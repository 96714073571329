import React from 'react'
import Parse from 'parse'
//import Button from '../../style/elements/Button'
//import Box from '../../layout/Box'

//import MainHeading from '../../style/text/MainHeading'
//import SubHeading from '../../style/text/SubHeading'


//import {rem} from '../../../lib/tools'
import shortid from "shortid";
//import Select from "../../style/elements/Select";
//import Flex from "../../layout/Flex";


import queryString from 'qs'
import {Box, Button, Flex, Text} from "givapp-design";
import Select from "givapp-design/src/components/Select";
import {rem} from "givapp-design/src/lib/tools";
import * as _ from "lodash";

export class SelectOrganization2 extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedOrganization: '',
      selectedChildOrganization: '',
      uuid: '',
      organizations: [],
      childOrganizations: [],
      disableForm: true
    }


    this.fetchOrganizations = this.fetchOrganizations.bind(this)
  }

  handleChange = (key, e) => (
    e => this.setState({[key]: e.target.value})
  )

  handleSelectChange = (key) =>
    (value) => {
      console.log('handleSelectChange', value);
      this.setState({[key]: value}, () => {
          console.log(key, this.state[key]);
          if (key === 'selectedOrganization') {
            this.fetchChildOrganizations();
          }
        }
      );
    }


  componentDidMount() {

    if (this.props.location.search) {

      const values = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true})

      const {organizationId} = values;

      if (organizationId) {
        this.setState({'disableForm': true});

        Parse.Cloud.run('fetchAllOrganizations', {}).then((organizations) => {

          Parse.Cloud.run('fetchOrganization', {organization: organizationId}).then((organization) => {
            let parentOrganization = organization.get('parentOrganization');
            if (parentOrganization) {
              this.setState({'selectedOrganization': parentOrganization.id});
              Parse.Cloud.run('fetchAllChildOrganizations', {organization: parentOrganization.id}).then((subaccounts) => {
                this.setState({'selectedChildOrganization': organization.id});
                this.setState({organizations: organizations})
                this.setState({childOrganizations: subaccounts.filter(subaccount => subaccount.get('live'))});
              });
            } else {
              this.setState({organizations: organizations})
              this.setState({'selectedOrganization': organization.id});
            }
          });

        });
      } else {
        this.fetchOrganizations();
      }

    } else {
      this.fetchOrganizations();
    }

    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  fetchOrganizations = () => {
    this.setState({'disableForm': false});
    Parse.Cloud.run('fetchAllOrganizations', {}).then((organizations) => {
      this.setState({organizations: organizations})
    });
  }


  fetchChildOrganizations = () => {
    //fetchAllChildOrganizations
    Parse.Cloud.run('fetchAllChildOrganizations', {organization: this.state.selectedOrganization.value}).then((result) => {
      if (this._isMounted === true) this.setState({childOrganizations: result.filter(subaccount => subaccount.get('live'))})
    })
  }


  submit(event) {
    event.preventDefault()
    console.log('SelectOrganization2', this.state.selectedOrganization, this.state.selectedChildOrganization);
    const {
      selectedOrganization,
      selectedChildOrganization
    } = this.state

    this.props.history.push('/donate/payment-details', {
      organization: selectedOrganization.value,
      subaccount: selectedChildOrganization.value
    })
  }


  render() {
    console.log('SelectOrganization2', this.state);
    const {
      selectedOrganization,
      selectedChildOrganization,
      organizations,
      childOrganizations,
      disableForm,
    } = this.state

    const organizationsChoices = _.map(organizations, (row) => {
      return {
        label: row.get('name'),
        value: row.id
      }
    });

    const childOrganizationChoices = _.map(childOrganizations, (row) => {
      return {
        label: row.get('name'),
        value: row.id
      }
    });

    if (organizations) {

      return (
        <Box>
          <Text title1>Giv to an organization of your choice</Text>
          <Text title2>Select an organization</Text>
          <form onSubmit={(e) => this.submit(e)} method="post">
            <Box mt={rem(2)}>
              <Select
                small
                disabled={disableForm}
                options={organizationsChoices}
                placeholder="Select Organization"
                onChange={selected => this.handleSelectChange('selectedOrganization')(selected)}
              />
            </Box>

            {!!childOrganizations.length > 0 &&
            <Box mt={rem(2)}>
              <Text title2>You may optionally select a subaccount</Text>
              <Select
                small
                disabled={disableForm}
                options={childOrganizationChoices}
                placeholder="Select Subaccount"
                onChange={selected => this.handleSelectChange('selectedChildOrganization')(selected)}
              />
            </Box>}
            <Flex mt={2} justifyContent="space-between">
              <Button htmlButton disabled={!selectedOrganization}>Confirm</Button>
            </Flex>
          </form>
        </Box>
      )
    } else {
      return (
        <Box>
          <Text title1>Loading...</Text>
        </Box>
      )
    }
  }
}

export default SelectOrganization2
