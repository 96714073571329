import React from 'react'
import {Route, Switch} from 'react-router-dom'
import AuthenticatedRoute from './AuthenticatedRoute'
import AdminRoute from './AdminRoute'

import GeneratedMonthlySummaryStatement from '../pages/GeneratedMonthlySummaryStatement'
import GeneratedIndividualDonorStatement from '../pages/GeneratedIndividualDonorStatement'

import AdminActivity from '../pages/admin/Activity'
import AdminNotifications from '../pages/admin/Notifications'
import AdminStatements from '../pages/admin/Statements'
import GeneratedMonthlyDonationsToAllOrganizations from '../pages/admin/GeneratedMonthlyDonationsToAllOrganizations'
import GeneratedOrganizationFinancialStatement from '../pages/admin/GeneratedOrganizationFinancialStatement'
import GeneratedDonorStatementForAdmin from '../pages/admin/GeneratedDonorStatementForAdmin'
import Terminal from "../pages/admin/Terminal";
import PointOfSale from "../pages/PointOfSale";
import Dashboard from "../pages/organization/Dashboard";
import Campaigns from "../pages/organization/Campaigns";
import Donors from "../pages/organization/Donors";
import Donations from "../pages/organization/Donations";
import Invoicing from "../pages/organization/Invoicing";
import Messages from "../pages/organization/Messages";
import Households from "../pages/organization/Households";
import Donor from "../pages/organization/Donor";
import Organization from "../pages/organization/Organization";
import Bridge from '../pages/organization/bridge/Bridge'
import Article from '../pages/organization/bridge/Article'


const AccountRoutes = ({match, ...props}) => {
  console.log('AccountRoutes', props);
  const {setPageId, user, organization, subaccounts, selectedTheme} = props;
  return (
    /*Header*/

    <Switch>
      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}`}
        component={() => <Dashboard {...props}/>}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}articles`}
        component={() => <Bridge {...props} />}
        />

        <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}articles/new`}
        component={(subprops) => <Article {...subprops} {...props} new={true} />}
      />
        <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}articles/:article_id`}
        component={(subprops) => <Article {...subprops} {...props} />}
      />

        

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}dashboard`}
        component={() => <Dashboard {...props} />}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}campaigns`}
        component={() => <Campaigns {...props} />}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}organization`}
        component={() => <Organization {...props}/>}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}campaigns/new`}
        component={(subprops) => <Organization {...subprops} {...props} new={true}/>}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}campaigns/:campaign_id`}
        component={(subprops) => <Organization {...subprops} {...props} />}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}donors`}
        component={() => <Donors {...props} />}
      />

      <AuthenticatedRoute
        user={props.user}
        path={`${match.url}donors/:donor_id`}
        component={(subprops) => <Donor {...subprops} {...props} />}
      />


      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}donations`}
        component={() => <Donations {...props} />}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}invoicing`}
        component={() => <Invoicing {...props} />}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}messages`}
        component={() => <Messages {...props} />}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}households`}
        component={() => <Households {...props} />}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}pos`}
        component={() => <PointOfSale {...props} />}
      />

      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}statements/generated/monthly-summary`}
        component={() => <GeneratedMonthlySummaryStatement {...props} />}
      />
      <AuthenticatedRoute
        exact
        user={props.user}
        path={`${match.url}statements/generated/individual-donor`}
        component={() => <GeneratedIndividualDonorStatement {...props} />}
      />

      <AdminRoute
        exact
        user={props.user}
        path={`${match.url}admin-activity`}
        component={() => <AdminActivity {...props} />}
      />
      <AdminRoute
        exact
        user={props.user}
        path={`${match.url}admin-notifications`}
        component={() => <AdminNotifications {...props} />}
      />
      <AdminRoute
        exact
        user={props.user}
        path={`${match.url}admin-statements`}
        component={() => <AdminStatements {...props} />}
      />

      <AdminRoute
        exact
        user={props.user}
        path={`${match.url}admin-statements/generated/monthly-donations-all-organizations`}
        component={() => <GeneratedMonthlyDonationsToAllOrganizations {...props} />}
      />
      <AdminRoute
        exact
        user={props.user}
        path={`${match.url}admin-statements/generated/organization-financial-statement`}
        component={() => <GeneratedOrganizationFinancialStatement {...props} />}
      />
      <AdminRoute
        exact
        user={props.user}
        path={`${match.url}admin-statements/generated/individual-donor-for-admin`}
        component={() => <GeneratedDonorStatementForAdmin {...props} />}
      />

      <AdminRoute
        exact
        user={props.user}
        path={`${match.url}admin/terminal`}
        component={() => <Terminal {...props} />}
      />

    </Switch>


  )

}

export default AccountRoutes

