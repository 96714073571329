import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'

import {login} from '../../lib/auth'
import {rem} from '../../lib/tools'
import Logo from "../Logo";
import Box from "givapp-design/src/components/Box";
import MainHeading from "../style/text/MainHeading";
import UIMessage from "givapp-design/src/components/UIMessage";
import Flex from "givapp-design/src/components/Flex";
import Button from "givapp-design/src/components/Button";
import Text from "givapp-design/src/components/Text";
import Input from "givapp-design/src/components/Input";
import {color, flexDirection, alignContent, alignItems, layout, borderRadius} from "styled-system";


export class Auth extends Component {
  state = {
    email: '',
    password: '',
    error: null
  }

  handleAuth = async e => {
    e.preventDefault()

    try {
      const user = await login(
        this.state.email.toLowerCase(),
        this.state.password
      )
      if (!!user) this.props.onComplete(user)
    } catch (error) {
      this.setState({error})
    }
  }

  handleChange = key => {
    return e => this.setState({[key]: e.target.value})
  }

  handlePassiveChange = key => {
    return e => this.state[key] = e.target.value;
  }

  render() {
    const {user} = this.props
    const {error, email, password} = this.state

    // Send to main page if logged in already
    if (user && !user.get('isAnonymous')) return <Redirect to="/"/>
    console.log('render');
    return (
      <Flex bg="gray" flexDirection={'column'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}
            height={'100vh'} mt={-9}>
        <Box maxWidth={400}>
          <Logo width={10}/>
        </Box>
        <Box mt={4} width={1 / 4} minWidth={320} maxWidth={400} border={1} borderRadius={2} p={"2rem"}>
          <MainHeading>Login</MainHeading>
          <Text minor>
            or sign up at <a href="https://givapp.com">GivApp.com</a>
          </Text>

          <Box mt={3}>
            {!!error &&
              <Box mb={3}>
                <UIMessage type="error">Error: {error.message}</UIMessage>
              </Box>
            }

            <form onSubmit={this.handleAuth} method="post">
              <Input
                required
                id="email"
                name="email"
                type="email"
                placeholder="Email address"
                value={email}
                onChange={this.handleChange('email')}
              />
              <Box mt={3}>
                <Input
                  required
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="Password"
                  onChange={this.handlePassiveChange('password')}
                />
                <Box mt={1}>
                  <Text tiny right>
                    <Link to="/forgot-password">Forgot password?</Link>
                  </Text>
                </Box>
              </Box>

              <Flex mt={3} flexDirection={'column'}>
                <Box>
                  <Button htmlButton full type="submit" w={[1.0]}>Login</Button>
                </Box>
              </Flex>
            </form>
          </Box>
        </Box>
      </Flex>
    )
  }
}

export default Auth
